<template>
  <div class="user-account-info">
    <div class="current-subscription">
      <h2 class="component-title">{{ $t("Subscription") }}</h2>
      <p class="component-description">{{ $t("Subscription description") }}</p>
      <v-card class="pa-5" v-if="!editMode">
        <v-card-text>
          <v-row>
            <v-col>
              <label class="has-float-label">
                <span class="read-only">{{ $t("Days of recording") }}</span>
                <p v-if="storageDays">{{ storageDays }} {{ $t("days") }}</p>
                <p v-else>-</p>
              </label>
            </v-col>
            <v-col>
              <label class="has-float-label">
                <span class="read-only">{{ $t("Video quality") }}</span>
                <p v-if="quality">{{ videoQuality(quality) }}</p>
                <p v-else>-</p>
              </label>
            </v-col>
            <v-col>
              <label class="has-float-label">
                <span class="read-only">{{
                  $t("Max. number of cameras")
                }}</span>
                <p v-if="maxAmountOfCameras !== null && maxAmountOfCameras > 0">
                  {{ maxAmountOfCameras }}
                </p>
                <p v-else>-</p>
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label class="has-float-label">
                <span class="read-only">{{ $t("Start date") }}</span>
                <p v-if="currentSubscription && currentSubscription.startDate">
                  {{
                    new Date(currentSubscription.startDate)
                      .toISOString()
                      .substring(0, 10)
                  }}
                </p>
              </label>
            </v-col>
            <v-col>
              <label class="has-float-label">
                <span class="read-only">{{ $t("End date") }}</span>
                <p v-if="currentSubscription && currentSubscription.endDate">
                  {{
                    new Date(currentSubscription.endDate)
                      .toISOString()
                      .substring(0, 10)
                  }}
                </p>
              </label>
            </v-col>
            <v-col v-if="!isCustomSubscription">
              <label class="has-float-label">
                <span class="read-only">{{ $t("SKU") }}</span>
                <p>{{ sku }}</p>
              </label>
            </v-col>
            <v-col v-else>
              <label class="has-float-label">
                <span class="read-only">{{ $t("Subscription") }}</span>
                <p :class="{ 'has-text-danger': !isCurrentSubscriptionValid }">
                  {{ name }}
                </p>
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mx-10 pa-0" v-if="editMode">
        <v-card-text class="pa-0">
          <v-row>
            <v-col class="pa-0">
              <div v-if="hasDemoOrTrial && !selectedRegular">
                <v-select
                  id="add-user-account-type"
                  :label="$t('User type')"
                  :items="accountTypes"
                  v-model="accountType"
                  :selected-option="selectedAccountType"
                  dense
                  class="zIndex100"
                  @change="onSelectAccountType"
                  :placeholder="$t('User type')"
                ></v-select>
                <v-btn
                  large
                  class="float-right mt-4"
                  color="primary"
                  :disabled="accountType !== 'regular'"
                  @click="selectedRegular = true"
                  >{{ $t("Next") }}</v-btn
                >
              </div>
              <choose-subscription
                v-else
                @subscriptionSelected="onSubscriptionSelected"
                :subscription="currentSubscription"
                :subscriptions="subscriptions"
              ></choose-subscription>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div
        :class="{ 'is-active': isEditActive }"
        class="dropdown edit-menu"
        v-if="canEditSubscription"
        ref="dropDownEditMenu"
      >
        <v-menu offset-y auto>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon size="1em" color="primary">fa fa-ellipsis-h</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="edit">
              <v-list-item-title class="pr-5">{{
                $t("Edit")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="control-buttons">
        <v-btn
          class="mx-2"
          fab
          small
          color="primary"
          @click="cancelSaveSubscription()"
          v-if="editMode"
        >
          <v-icon dark>fas fa-times</v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          small
          color="primary"
          @click="saveSubscription()"
          :disabled="!isDirty"
          v-if="editMode"
        >
          <v-icon dark>fas fa-save</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="prolonged-subscription" v-if="!editMode">
      <h2 class="component-title">{{ $t("Prolonged subscription") }}</h2>
      <p class="component-description" v-if="isProlonged">
        {{ prolongDescription }}
      </p>
      <div
        class="columns is-dashed pt-6"
        :class="{ 'is-prolonged': isProlonged }"
        v-if="isProlonged"
      >
        <v-row>
          <v-col v-if="nextSubscription && !isCustomNextSubscription">
            <label class="has-float-label">
              <span class="read-only">{{ $t("Days of recording") }}</span>
              <p v-if="nextSubscription && nextStorageDays">
                {{ nextStorageDays }} {{ $t("days") }}
              </p>
              <p v-else>-</p>
            </label>
          </v-col>
          <v-col v-else>
            <label class="has-float-label">
              <span class="read-only">{{ $t("Subscription") }}</span>
              <p :class="{ 'has-text-danger': !isNextSubscriptionValid }">
                {{ nextSubscription.name }}
              </p>
            </label>
          </v-col>
          <v-col v-if="nextSubscription">
            <label class="has-float-label">
              <span class="read-only">{{ $t("Video quality") }}</span>
              <p v-if="nextSubscription.quality">
                {{ videoQuality(nextSubscription.quality) }}
              </p>
              <p v-else>-</p>
            </label>
          </v-col>
          <v-col>
            <label class="has-float-label">
              <span class="read-only">{{ $t("Max. number of cameras") }}</span>
              <p v-if="nextSubscription && nextSubscription.maxAmountOfCameras">
                {{ nextSubscription.maxAmountOfCameras }}
              </p>
              <p v-else>-</p>
            </label>
          </v-col>
        </v-row>
      </div>
      <div class="columns is-dashed pt-6" v-if="!isProlonged">
        <v-row>
          <v-col>
            <p
              v-if="currentSubscription && currentSubscription.endDate"
              class="pl-4"
            >
              {{
                $t("Subscription will not be prolonged at", {
                  subscriptionEnd: new Date(currentSubscription.endDate)
                    .toISOString()
                    .substring(0, 10)
                })
              }}
            </p>
            <p class="has-text-danger" v-if="!isCurrentSubscriptionValid">
              {{
                $t(
                  "The subscription cannot be prolonged since the current subscription is invalid."
                )
              }}
            </p>
          </v-col>
        </v-row>
      </div>
      <div
        :class="{
          'is-active': isProlongEditActive,
          'is-prolonged': isProlonged
        }"
        class="dropdown edit-menu"
        v-if="canEditProlongedSubscription"
        ref="dropDownProlongEditMenu"
      >
        <v-menu offset-y auto>
          <template v-slot:activator="{ on }">
            <a v-on="on">
              <v-icon class="pa-3 mr-2" size="1em" color="primary"
                >fa fa-ellipsis-h</v-icon
              >
            </a>
          </template>
          <v-list dense>
            <v-list-item
              v-if="canCancelDowngrade"
              @click="showPopup('cancel_downgrade')"
            >
              <v-list-item-title class="pr-5">{{
                $t("Cancel downgrade")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canProlongSubscription"
              @click="showPopup('prolong')"
            >
              <v-list-item-title class="pr-5">{{
                $t("Prolong subscription")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canCancelSubscription"
              @click="showPopup('cancel_subscription')"
            >
              <v-list-item-title class="pr-5">{{
                $t("Do not prolong subscription")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-dialog
        width="600px"
        v-model="isPopupShown"
        id="custom-modal"
        :active="isPopupShown"
        @close="popupClosed()"
      >
        <v-card>
          <v-form v-on:submit.prevent="onConfirm">
            <v-card-title class="headline">
              {{ getModalTitle }}
            </v-card-title>
            <v-card-text>
              <slot name="content">
                {{ getModalContent }}
              </slot>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  class="mt-2"
                  outlined
                  color="primary"
                  type="button"
                  @click="onCancel()"
                  >{{ $t("Cancel") }}</v-btn
                >
                <v-btn
                  large
                  class="mt-2"
                  id="block-user-button"
                  color="primary"
                  type="submit"
                  >{{ $t("Confirm") }}</v-btn
                >
              </v-card-actions>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script charset="utf-8">
import ChooseSubscription from "@/components/settings/ChooseSubscription";

import restapi from "@eencloud/core-components/src/service/CMApi";

const CM_LIVE_ONLY_GROUP_NAME = "CM10";
const CM_LIVE_ONLY_DAYS_STORAGE = "0";
const CM_LIVE_ONLY_MAX_CAMERAS = 200;

const PROLONG_STATE_AUTO = "auto";
const PROLONG_STATE_DOWNGRADE = "downgrade";
const PROLONG_STATE_CANCEL = "cancel";
const PROLONG_STATE_DEFAULT = PROLONG_STATE_AUTO;

const MODAL_ACTION_CANCEL_DOWNGRADE = "cancel_downgrade";
const MODAL_ACTION_PROLONG = "prolong";
const MODAL_ACTION_CANCEL_SUBSCRIPTION = "cancel_subscription";

export default {
  name: "Subscription",
  props: [
    "subscriptions",
    "resellerId",
    "account",
    "userId",
    "currentSubscription",
    "nextSubscription"
  ],
  data() {
    return {
      isEditActive: false,
      isProlongEditActive: false,
      editMode: false,
      accountType: "regular",
      selectedRegular: false,
      subscription: {
        isCloudStorage: true,
        videoQuality: null,
        daysOfStorage: null,
        numberOfCameras: 1,
        sku: null
      },
      selectedSubscription: null,
      selectedSubscriptionName: null,
      selectedSubscriptionCameras: null,
      maxCamerasNumber: CM_LIVE_ONLY_MAX_CAMERAS,
      subscriptionTypeLeftLabel: this.$t("Cloud storage"),
      subscriptionTypeRightLabel: "Camera management / live only",
      videoQualities: [
        {
          value: "SD1",
          label: this.$t("subscription_type_sd1")
        },
        {
          value: "HD1",
          label: this.$t("subscription_type_hd1")
        },
        {
          value: "HD2",
          label: this.$t("subscription_type_hd2")
        }
      ],
      daysOfStorage: [
        {
          value: 7,
          label: this.$t("days_of_storage_7"),
          maxCameras: 200
        },
        {
          value: 14,
          label: this.$t("days_of_storage_14"),
          maxCameras: 200
        },
        {
          value: 30,
          label: this.$t("days_of_storage_30"),
          maxCameras: 200
        },
        {
          value: 60,
          label: this.$t("days_of_storage_60"),
          maxCameras: 120
        },
        {
          value: 90,
          label: this.$t("days_of_storage_90"),
          maxCameras: 80
        },
        {
          value: 180,
          label: this.$t("days_of_storage_180"),
          maxCameras: 60
        },
        {
          value: 365,
          label: this.$t("days_of_storage_365"),
          maxCameras: 20
        },
        {
          value: 730,
          label: this.$t("days_of_storage_730"),
          maxCameras: 10
        }
      ],
      isPopupShown: false,
      modalAction: false
    };
  },
  components: {
    ChooseSubscription
  },
  computed: {
    storageDays() {
      return this.currentSubscription
        ? Math.ceil(this.currentSubscription.retention / 24)
        : null;
    },
    quality() {
      return this.currentSubscription ? this.currentSubscription.quality : null;
    },
    maxAmountOfCameras() {
      return this.currentSubscription
        ? this.currentSubscription.maxAmountOfCameras
        : null;
    },
    name() {
      return this.currentSubscription
        ? this.isCurrentSubscriptionValid
          ? this.currentSubscription.name
          : this.$t("Invalid subscription")
        : null;
    },
    sku() {
      return this.currentSubscription ? this.currentSubscription.sku : null;
    },
    nextStorageDays() {
      return this.nextSubscription
        ? Math.ceil(this.nextSubscription.retention / 24)
        : null;
    },
    nextQuality() {
      return this.nextSubscription ? this.nextSubscription.quality : null;
    },
    nextMaxAmountOfCameras() {
      return this.nextSubscription ? this.nextSubscription.quality : null;
    },
    nextName() {
      return this.nextSubscription ? this.nextSubscription.name : null;
    },
    nextSku() {
      return this.nextSubscription ? this.nextSubscription.sku : null;
    },
    availableSubscriptions() {
      return this.subscriptions
        ? this.subscriptions.filter(function(s) {
            return s.available;
          })
        : null;
    },
    subscriptionStartDate() {
      return this.currentSubscription
        ? this.currentSubscription.startDate
        : null;
    },
    subscriptionEndDate() {
      return this.currentSubscription ? this.currentSubscription.endDate : null;
    },
    nextSubscriptionId() {
      return this.nextSubscription
        ? this.nextSubscription.subscriptionId
        : null;
    },
    subscriptionId() {
      return this.currentSubscription
        ? this.currentSubscription.subscriptionId
        : null;
    },
    prolongState() {
      if (this.willBeProlonged && this.willHaveSameNextSubscription) {
        return PROLONG_STATE_AUTO;
      } else if (
        this.willBeProlonged &&
        this.willHaveDifferentNextSubscription
      ) {
        return PROLONG_STATE_DOWNGRADE;
      } else if (!this.willBeProlonged) {
        return PROLONG_STATE_CANCEL;
      }
      return PROLONG_STATE_DEFAULT;
    },
    prolongDescription() {
      if (this.prolongState === PROLONG_STATE_CANCEL) {
        return "";
      } else if (this.prolongState === PROLONG_STATE_DOWNGRADE) {
        return this.$t("Subscription will be downgraded to", {
          subscriptionEnd: this.subscriptionEndDate
        });
      } else {
        return this.$t("Subscription will be prolonged to", {
          subscriptionEnd: this.subscriptionEndDate
        });
      }
    },
    getModalTitle() {
      if (this.modalAction === MODAL_ACTION_CANCEL_DOWNGRADE) {
        return this.$t("Cancel downgrade");
      } else if (this.modalAction === MODAL_ACTION_CANCEL_SUBSCRIPTION) {
        return this.$t("Cancel subscription");
      } else if (this.modalAction === MODAL_ACTION_PROLONG) {
        return this.$t("Prolong subscription");
      } else {
        return "";
      }
    },
    getModalContent() {
      if (this.modalAction === MODAL_ACTION_CANCEL_DOWNGRADE) {
        return this.$t("Are you sure to cancel downgrade");
      } else if (this.modalAction === MODAL_ACTION_CANCEL_SUBSCRIPTION) {
        return this.$t("Are you sure to cancel subscription");
      } else if (this.modalAction === MODAL_ACTION_PROLONG) {
        return this.$t("Are you sure to prolong subscription");
      } else {
        return "";
      }
    },
    canCancelDowngrade() {
      return this.prolongState === PROLONG_STATE_DOWNGRADE;
    },
    canProlongSubscription() {
      return (
        this.prolongState === PROLONG_STATE_CANCEL &&
        this.currentSubscription &&
        this.isCurrentSubscriptionValid
      );
    },
    canCancelSubscription() {
      return this.prolongState !== PROLONG_STATE_CANCEL;
    },
    isProlonged() {
      return this.prolongState !== PROLONG_STATE_CANCEL;
    },
    isDirty() {
      if (
        this.selectedSubscription &&
        this.selectedSubscription.subscriptionId
      ) {
        return (
          this.selectedSubscription.subscriptionId !==
          parseInt(this.subscriptionId)
        );
      }
      return null;
    },
    selectedDaysOfStorage() {
      return this.subscription.daysOfStorage;
    },
    selectedVideoQuality() {
      return this.subscription.quality;
    },
    selectedNumberOfCameras() {
      return this.subscription.numberOfCameras;
    },
    selectedCloudStorage() {
      return this.subscription.isCloudStorage;
    },
    isOldSubscription() {
      return this.customerAccount ? !this.customerAccount.available : null;
    },
    getInitialCamerasNumberValue() {
      return Number(this.subscription.numberOfCameras);
    },
    customerAccount() {
      if (this.subscriptionId) {
        const sId = parseInt(this.subscriptionId);
        if (this.subscriptions) {
          let account = this.subscriptions.find(function(s) {
            return s.subscriptionId === sId;
          });
          if (account) {
            account.storageDays = Math.ceil(account.retention / 24);
            return account;
          }
        }
      }
      return null;
    },
    customerNextAccount() {
      if (this.nextSubscriptionId) {
        const sId = parseInt(this.nextSubscriptionId);
        if (this.subscriptions) {
          let account = this.subscriptions.find(function(s) {
            return s.subscriptionId === sId;
          });
          if (account) {
            account.storageDays = Math.ceil(account.retention / 24);
            return account;
          }
        }
      }
      return null;
    },
    isBrowserIE() {
      return this.$store.getters.isIE;
    },
    willBeProlonged() {
      return this.nextSubscriptionId && this.nextSubscriptionId !== -1;
    },
    willHaveSameNextSubscription() {
      return (
        this.currentSubscription.subscriptionId === this.nextSubscriptionId
      );
    },
    willHaveDifferentNextSubscription() {
      return !this.willHaveSameNextSubscription;
    },
    isCustomSubscription() {
      if (this.availableSubscriptions) {
        return (
          this.availableSubscriptions[0] &&
          (this.availableSubscriptions[0].sku === null ||
            this.availableSubscriptions[0].quality === null ||
            this.availableSubscriptions[0].retention === null ||
            this.availableSubscriptions[0].maxAmountOfCameras === null)
        );
      }
      return null;
    },
    customSubscriptions() {
      let subscriptions = [];
      const nofSubscriptions = this.availableSubscriptions.length;
      for (let i = 0; i < nofSubscriptions; i++) {
        let name = this.availableSubscriptions[i].name;
        let cams = 0;
        let splittedName = name.split("_");
        if (splittedName.length > 1) {
          cams = parseInt(splittedName.pop());
          name = splittedName.join("_");
        } else {
          cams = parseInt(this.availableSubscriptions[i].maxAmountOfCameras);
        }
        let sub = subscriptions.find(function(s) {
          return s.name === name;
        });
        if (sub) {
          sub.cams.push({ value: cams, text: cams.toString() });
        } else {
          sub = {
            name: name,
            cams: [{ value: cams, text: cams.toString() }]
          };
          subscriptions.push(sub);
        }
      }
      for (let i = 0; i < subscriptions.length; i++) {
        subscriptions[i].cams = subscriptions[i].cams.sort(function(a, b) {
          return a.value - b.value;
        });
      }
      return subscriptions;
    },
    customSubscriptionCameras() {
      return this.selectedSubscriptionName
        ? this.selectedSubscriptionName.cams
        : [];
    },
    isCurrentSubscriptionValid() {
      return (
        !this.currentSubscription.hasOwnProperty("valid") ||
        this.currentSubscription.valid
      );
    },
    isNextSubscriptionValid() {
      return (
        !this.nextSubscription.hasOwnProperty("valid") ||
        this.nextSubscription.valid
      );
    },
    isCustomNextSubscription() {
      return this.nextSubscription
        ? this.nextSubscription.sku === null ||
            this.nextSubscription.quality === null ||
            this.nextSubscription.retention === null ||
            this.nextSubscription.maxAmountOfCameras === null
        : null;
    },
    selectedAccountType() {
      let currentAccountType = this.accountType;
      return this.accountTypes.find(function(t) {
        return t.value === currentAccountType;
      });
    },
    trialAccountType() {
      if (this.availableSubscriptions) {
        return this.availableSubscriptions.find(function(s) {
          return s.name.toLowerCase().indexOf("trial") !== -1;
        });
      }
      return null;
    },
    demoAccountType() {
      if (this.availableSubscriptions) {
        return this.availableSubscriptions.find(function(s) {
          return s.name.toLowerCase().indexOf("demo") !== -1;
        });
      }
      return null;
    },
    hasDemoOrTrial() {
      // return !!this.demoAccountType || !!this.trialAccountType;
      return false;
    },
    accountTypes() {
      let types = [
        {
          value: "regular",
          text: this.$t("Regular")
        }
      ];
      if (this.trialAccountType) {
        types.push({
          value: "trial",
          text: this.$t("Trial")
        });
      }
      if (this.demoAccountType) {
        types.push({
          value: "demo",
          text: this.$t("Demo")
        });
      }
      return types;
    },
    isUserActive() {
      return this.account ? this.account.active : false;
    },
    canEditSubscription() {
      return !this.editMode;
    },
    canEditProlongedSubscription() {
      return (
        !this.editMode &&
        this.isUserActive &&
        (this.canCancelDowngrade ||
          this.canProlongSubscription ||
          this.canCancelSubscription)
      );
    }
  },
  methods: {
    onSelectAccountType(t) {
      this.accountType = t;
      if (this.accountType === "demo") {
        this.selectedSubscription = this.demoAccountType;
      } else if (this.accountType === "trial") {
        this.selectedSubscription = this.trialAccountType;
      } else {
        this.selectedSubscription = this.currentSubscription;
      }
    },
    onSubscriptionSelected(subscription) {
      this.selectedSubscription = subscription;
    },
    showPopup(type) {
      if (
        type === MODAL_ACTION_CANCEL_DOWNGRADE ||
        type === MODAL_ACTION_CANCEL_SUBSCRIPTION ||
        type === MODAL_ACTION_PROLONG
      ) {
        this.modalAction = type;
        this.isPopupShown = true;
        this.isProlongEditActive = false;
      }
    },
    popupClosed() {
      this.modalAction = null;
      this.isPopupShown = false;
    },
    onConfirm() {
      if (this.modalAction === MODAL_ACTION_CANCEL_DOWNGRADE) {
        this.cancelDowngrade();
      } else if (this.modalAction === MODAL_ACTION_CANCEL_SUBSCRIPTION) {
        this.cancelSubscription();
      } else if (this.modalAction === MODAL_ACTION_PROLONG) {
        this.prolongSubscription();
      }
      this.modalAction = null;
      this.isPopupShown = false;
    },
    onCancel() {
      this.modalAction = null;
      this.isPopupShown = false;
    },
    cancelDowngrade() {
      this.prolongSubscription();
    },
    prolongSubscription() {
      let that = this;
      // that.isLoading(true);
      restapi
        .updateMyAccountSubscription( this.userId, this.subscriptionId)
        .then(function() {
          that.$store.commit("toastMessage", {
            showing: true,
            text: that.$t("Subscription was prolonged successfully"),
            timeout: 4000,
            color: "primary"
          });
          that.$emit("changed");
          return true;
        })
        .catch(function() {
          that.$store.commit("toastMessage", {
            showing: true,
            text: that.$t("Error while updating subscription"),
            timeout: -1,
            color: "error"
          });
          return false;
        })
        .then(function() {
          // that.isLoading(false);
        });
    },
    cancelSubscription() {
      let that = this;
      // that.isLoading(true);
      restapi
        .cancelAccountSubscription(this.resellerId, this.userId)
        .then(function() {
          that.$store.commit("toastMessage", {
            showing: true,
            text: that.$t("Subscription was canceled successfully"),
            timeout: 4000,
            color: "primary"
          });
          that.$emit("changed");
          return true;
        })
        .catch(function() {
          that.$store.commit("toastMessage", {
            showing: true,
            text: that.$t("Error while canceling subscription"),
            timeout: -1,
            color: "error"
          });
          return false;
        })
        .then(function() {
          // that.isLoading(false);
        });
    },
    edit() {
      this.selectedRegular = false;
      this.editMode = true;
      this.isEditActive = false;
      if (this.isCustomSubscription) {
        let name = this.customerAccount.name.split("_");
        let cams = name.pop();
        name = name.join("_");
        const sub = this.customSubscriptions.find(function(s) {
          return s.name === name;
        });
        this.selectedSubscriptionName = sub;
        this.selectedSubscriptionCameras = {
          value: parseInt(cams),
          text: cams
        };
      }
    },
    videoQuality(quality) {
      if (quality) {
        if (quality.includes("CM10")) return "CM10 No cloud storage";
        else if (quality.includes("HD1")) return "HD1 720p";
        else if (quality.includes("HD2")) return "HD2 1080p";
        else if (quality.includes("SD1")) return "SD1 VGA";
      }
      return null;
    },
    onSelectCustomSubscriptionName(name) {
      this.selectedSubscriptionName = name;
      this.selectedSubscriptionCameras = null;
      this.selectedSubscription = null;
    },
    onSelectCustomSubscriptionCameras(cams) {
      this.selectedSubscriptionCameras = cams;
      const name = this.selectedSubscriptionName.name;
      const nofCams = cams.value;
      this.selectedSubscription = this.availableSubscriptions.find(function(s) {
        return s.name.indexOf(name) !== -1 && s.maxAmountOfCameras === nofCams;
      });
    },
    saveSubscription() {
      let that = this;
      // that.isLoading(true);
      restapi
        .updateMyAccountSubscription(
          this.userId,
          this.selectedSubscription.subscriptionId
        )
        .then(function() {
          that.editMode = !that.editMode;
          that.$emit("changed");
          return true;
        })
        .catch(function() {
          that.$store.commit("toastMessage", {
            showing: true,
            text: that.$t("Error while updating subscription."),
            timeout: 4000,
            color: "primary"
          });
          return false;
        })
        .then(function() {
          // that.isLoading(false);
        });
    },
    cancelSaveSubscription() {
      this.editMode = !this.editMode;
      this.$emit("cancel");
      // clean the panel to the initial values
      if (this.customerAccount) {
        this.subscription.videoQuality = this.customerAccount.quality;
        this.subscription.daysOfStorage =
          this.customerAccount.storageHours / 24;
        this.subscription.numberOfCameras = Math.max(
          0,
          this.customerAccount.maxAmountOfCameras
        );
        this.subscription.sku = this.customerAccount.sku;
        this.subscription.isCloudStorage =
          this.customerAccount.quality !== CM_LIVE_ONLY_GROUP_NAME;
      } else {
        this.subscription.videoQuality = null;
        this.subscription.daysOfStorage = null;
        this.subscription.numberOfCameras = 1;
        this.subscription.sku = null;
        this.subscription.isCloudStorage = true;
      }
    },
    getIdForVideoQualityButton(item) {
      return item.value.toString().toLowerCase() + "-video-quality-button";
    },
    getIdForDaysOfStorageButton(item) {
      return "days-storage-" + item.value.toString().toLowerCase() + "-button";
    },
    updateSubscriptionData() {
      if (this.subscription) {
        let quality = this.subscription.videoQuality;
        let cams = this.subscription.numberOfCameras;
        let days = this.subscription.daysOfStorage;

        if (quality && cams > 0 && days) {
          this.selectedSubscription = this.availableSubscriptions.find(function(
            s
          ) {
            return (
              s.quality === quality &&
              s.maxAmountOfCameras === cams &&
              s.retention === days * 24
            );
          });
        }
      }
    },
    onSelectCustomSubscription(subscription) {
      this.selectedSubscription = subscription;
    },
    subscriptionTypeChanged(value) {
      this.subscription.isCloudStorage = !value;
    },
    cameraNumberChanged(value) {
      this.subscription.numberOfCameras = value;
    },
    getMaxCamerasNumber(value) {
      for (let i = 0; i < this.daysOfStorage.length; i++) {
        if (this.daysOfStorage[i].value === value) {
          return this.daysOfStorage[i].maxCameras;
        }
      }
      return 0;
    },
    isDayButtonSelected(value) {
      let days = this.subscription.daysOfStorage;
      return days === value;
    },
    setDaysOfStorage(value) {
      this.subscription.daysOfStorage = value;
    },
    isVideoQualityButtonSelected(value) {
      let videoQuality = this.subscription.videoQuality;
      return (
        videoQuality &&
        (videoQuality === value || videoQuality.indexOf(value) !== -1)
      );
    },
    setVideoQuality(value) {
      this.subscription.videoQuality = value;
    },
    documentClick(e) {
      let dropDownEditMenu = this.$refs.dropDownEditMenu;
      let dropDownProlongEditMenu = this.$refs.dropDownProlongEditMenu;

      let target = e.target;
      if (
        dropDownEditMenu &&
        dropDownEditMenu !== target &&
        !dropDownEditMenu.contains(target)
      ) {
        this.isEditActive = false;
      }
      if (
        dropDownProlongEditMenu &&
        dropDownProlongEditMenu !== target &&
        !dropDownProlongEditMenu.contains(target)
      ) {
        this.isProlongEditActive = false;
      }
    }
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
  watch: {
    customerAccount: function(newVal) {
      if (newVal) {
        this.subscription.videoQuality = this.customerAccount.quality;
        this.subscription.daysOfStorage = this.customerAccount.retention / 24;
        this.subscription.numberOfCameras = Math.max(
          0,
          this.customerAccount.maxAmountOfCameras
        );
        this.subscription.sku = this.customerAccount.sku;
        this.subscription.isCloudStorage =
          this.customerAccount.quality !== CM_LIVE_ONLY_GROUP_NAME;
      }
    },
    selectedDaysOfStorage: function(newVal) {
      if (newVal > 0) {
        this.maxCamerasNumber = this.getMaxCamerasNumber(newVal);
      }
      this.updateSubscriptionData();
    },
    selectedVideoQuality: function(newVal) {
      if (newVal === CM_LIVE_ONLY_GROUP_NAME) {
        this.subscription.isCloudStorage = false;
      }
      this.updateSubscriptionData();
    },
    selectedNumberOfCameras: function() {
      this.updateSubscriptionData();
    },
    selectedCloudStorage: function(newVal) {
      if (!newVal) {
        this.subscription.videoQuality = CM_LIVE_ONLY_GROUP_NAME;
        this.subscription.daysOfStorage = CM_LIVE_ONLY_DAYS_STORAGE;
        this.maxCamerasNumber = CM_LIVE_ONLY_MAX_CAMERAS;
      } else {
        this.subscription.videoQuality = this.customerAccount.quality;
        this.subscription.daysOfStorage = this.customerAccount.retention / 24;
        this.subscription.numberOfCameras = Math.max(
          0,
          this.customerAccount.maxAmountOfCameras
        );
        this.subscription.sku = this.customerAccount.sku;
      }
      this.updateSubscriptionData();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

.user-account-info {
  position: relative;
  padding-bottom: 40px;
  h2 {
    color: $primary;
    text-transform: uppercase;
    margin: 50px 0 0.541em 0;
    font-size: 14px;
  }
  .has-float-label {
    > span {
      top: 0;
    }
  }
  .columns {
    margin-top: 20px;
    padding: 5px 0;
    &.is-dashed {
      padding: 0;
      background-color: transparent;
      border: dashed 2px $color-title-light;
      border-radius: 4px;
      &.is-prolonged {
        padding: 0 0 10px 20px;
      }
      .column {
        margin: 30px 0;
        padding: 0 0 0 20px;
      }
    }
    .column {
      padding: 0 40px;
      margin: 35px 0 40px 0;
      .subscription-info {
        padding: 0 1.75rem 0.75rem 0;
      }
      .field {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .deactivate-partner-tooltip {
        left: -40px;
      }
      .choose-subscription-block {
        .custom-subscriptions {
          min-height: 100px;
        }
      }
    }
    &.edit-mode {
      .column {
        @media screen and (max-width: 767px) {
          border-left: 8px solid $primary;
        }
        @media screen and (min-width: 768px) {
          &:first-child {
            border-left: 8px solid $primary;
          }
        }
      }
    }
  }
  .current-subscription {
    position: relative;
    .dropdown {
      &.edit-menu {
        position: absolute;
        top: 70px;
        right: 0;
        z-index: 20;
        .dropdown-trigger {
          color: $primary;
          font-weight: bold;
          position: absolute;
          top: 0;
          right: 10px;
          cursor: pointer;
          @media screen and (max-width: 767px) {
            top: 20px;
          }
          span {
            font-weight: 600;
            font-size: 18px;
          }
        }
        .dropdown-menu {
          position: relative;
          margin-top: 25px;
          .dropdown-content {
            border-radius: $border-radius;
            .dropdown-item {
              text-align: center;
              padding: 0.375rem 1rem;
              text-transform: uppercase;
              color: $primary;
              &.is-danger {
                color: $color-error-dark;
              }
            }
            hr {
              height: 1px !important;
            }
          }
        }
      }
    }
  }
  .prolonged-subscription {
    position: relative;
    .dropdown {
      &.is-prolonged {
        &.edit-menu {
          top: 70px;
        }
      }
      &.edit-menu {
        position: absolute;
        top: 45px;
        right: 0;
        z-index: 20;
        .dropdown-trigger {
          color: $primary;
          font-weight: bold;
          position: absolute;
          top: 0;
          right: 10px;
          cursor: pointer;
          @media screen and (max-width: 767px) {
            top: 20px;
          }
          span {
            font-weight: 600;
            font-size: 18px;
          }
        }
        .dropdown-menu {
          position: relative;
          margin-top: 25px;
          .dropdown-content {
            border-radius: $border-radius;
            .dropdown-item {
              text-align: center;
              padding: 0.375rem 1rem;
              text-transform: uppercase;
              color: $primary;
              &.is-danger {
                color: $color-error-dark;
              }
            }
            hr {
              height: 1px !important;
            }
          }
        }
      }
    }
  }
  .control-buttons {
    padding: 0;
    width: 50px;
    display: inline-block;
    position: absolute;
    right: 7px;
    top: 70px;
    button {
      float: right;
      margin-bottom: 10px;
      font-size: 18px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      vertical-align: middle;
      &.cancel-button {
        color: $primary;
        background-color: #ffffff;
        border: 2px solid $primary;
      }
      &.save-button {
        color: #ffffff;
        background-color: $primary;
      }
    }
  }
  .modal-card {
    .modal-card-body {
      min-height: 180px;
    }
    .modal-card-foot {
      background-color: white;
    }
  }
}
.control-buttons {
  padding-top: 15px;
}
.video-quality-block,
.days-of-storage-block,
.number-of-cameras-block,
.sku-block {
  text-align: center;
}
.video-quality-block .buttons {
  justify-content: center;
}
p.title {
  color: #b9b9b9 !important;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 85% !important;
}
button {
  margin-right: 10px;
  margin-bottom: 10px;
  color: $color-action;
  background-color: white;
}
.days-of-storage-block .buttons {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.button.selected {
  color: #ffffff;
  background-color: $color-action;
}
.columns {
  justify-content: center;
}
.subscription-info p.prolonged-subscription-label {
  margin-bottom: 0;
}
</style>
