<template>
  <div class="settings--container__width">
    <div class="status--header">
      <h2 class="status--header__title">{{ $t("Camera notification") }}</h2>
      <p>{{ $t("Alerts when a camera is online/offline") }}</p>
      <v-card class="pr-5 pl-5">
        <v-form>
          <v-row>
            <v-col>
              <p class="subheading my-4">{{ $t("Alert me when a camera is online/offline") }}</p>
            </v-col>
            <v-col>
              <v-switch dense v-model="cameraDownEnabled"></v-switch>
            </v-col>
          </v-row>
          <template v-if="cameraDownEnabled">
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <div class="status--header__info">
                  <p class="subheading my-4">{{ $t("Alert cool off period") }}</p>
                  <v-menu max-width="500" nudge-bottom="30" open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon size="20" color="primary">fas fa-info-circle</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <div>
                          {{ $t("You will only get the alert if the camera is offline for more than this time")
                          }}
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col>
                <v-select class="mt-2" v-model="alertDelay" :items="alertDelayItems" item-value="value" item-text="text"
                  solo flat dense hide-details background-color="grey lighten-3"></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <p class="subheading my-4">{{ $t("Alert me by") }}</p>
              </v-col>
              <v-col>
                <v-row no-gutters>
                  <v-col>
                    <v-checkbox dense v-model="alertByPush" hide-details :label="$t('Push (Mobile)')"></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox dense v-model="alertByEmail" hide-details :label="$t('Email')"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <p class="subheading my-4">{{ $t("Repeat after") }}</p>
              </v-col>
              <v-col>
                <v-select class="mt-2 status--header" v-model="alertRepeat" :items="alertRepeatItems" item-value="value" item-text="text"
                  solo flat dense hide-details background-color="grey lighten-3" :menu-props="{ zIndex: 1001 }"></v-select>
              </v-col>
            </v-row>
          </template>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-card-actions>
                <v-btn @click="saveNotificatiions" color="primary">{{ $t("Save changes") }}</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import { t } from "@eencloud/core-components/src/service/locale";
import { store } from "@/store";
import restapi from "@eencloud/core-components/src/service/CMApi";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

export default {
  name: "StatusNotification",
  setup() {
    const state = reactive({
      cameraDownEnabled: false,
      alertDelay: 60,
      alertByEmail: null,
      alertByPush: null,
      alertDelayItems: null,
      alertRepeat: 0,
      alertRepeatItems: null,
    });

    initialLoad(state);

    const saveNotificatiions = useSaveNotificatiions(state);

    return {
      ...toRefs(state),
      saveNotificatiions,
    };
  },
};
function initialLoad(state) {
  onMounted(async () => {
    try {
      let response = await restapi.getNotificationSettings();
      let cameraDown = response.find((element) => element.type == "cameraDown");
      if (cameraDown) {
        state.cameraDownEnabled = cameraDown.enabled;
        state.alertDelay = cameraDown.alertDelay;
        state.alertByEmail = cameraDown.alertByEmail;
        state.alertByPush = cameraDown.alertByPush;
        state.alertRepeat = cameraDown.alertRepeat;
      }
    } catch (error) {
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "GET_NOTIFICATION_SETTINGS"),
        timeout: -1,
        color: "error",
      });
    }

    try {
      const response = await restapi.getNotificationSupportedSettings();
      if(response) {
        state.alertDelayItems = getDisplayTime(response.cameraOfflineAlertDelay);
        state.alertRepeatItems = getDisplayTime(response.cameraOfflineAlertRepeat);
      }
    } catch (error) {
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "GET_SUPPORTED_NOTIFICATION_SETTINGS"),
        timeout: -1,
        color: "error",
      });
    }
  });
}

function useSaveNotificatiions(state) {
  async function saveNotificatiions() {
    let payload = {
      enabled: state.cameraDownEnabled,
      alertByEmail: state.alertByEmail,
      alertByPush: state.alertByPush,
      alertDelay: state.alertDelay,
      alertRepeat: state.alertRepeat,
    };
    try {
      await restapi.addNotificationSettings("cameraDown", payload);
      store.dispatch("toastMessage", {
        showing: true,
        text: t("Your settings are successfully changed"),
        timeout: 4000,
        color: "primary",
      });
    } catch (error) {
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "ADD_NOTIFICATION_SETTINGS"),
        timeout: -1,
        color: "error",
      });
    }
  }
  return saveNotificatiions;
}

function getDisplayTime(minutes) {
  return minutes.map((minute)=> {
    if(minute === 0) {
      return {text: t("Never"), value: "0"};
    }
    const day = Math.floor(minute / 1440); 
    let remMin = minute % 1440;
    const hour = Math.floor(remMin / 60);
    remMin = remMin % 60;

    const minutes = remMin === 0 ? '' : `${remMin} ${t("minute"+(remMin > 1 ? "s" : '' ))}`;
    const hours = hour === 0 ? '' : `${hour} ${t("hour"+(hour > 1 ? "s" : ''))}`;
    const days = day === 0 ? '' : `${day} ${t("day"+(day > 1 ? "s" : ''))}`;

    if(days.length && hours.length && minutes.length) {
      return {text: `${days} ${hours} ${minutes}`, value: minute.toString()};  
    } else if(days.length && hours.length && !minutes.length) {
      return {text: `${days} ${hours}`, value: minute.toString()};  
    } else if(!days.length && hours.length && minutes.length) {
      return {text: `${hours} ${minutes}`, value: minute.toString()};  
    } else  if(days.length && !hours.length && minutes.length) {
      return {text: `${days} ${minutes}`, value: minute.toString()};  
    } else return {text: days+hours+minutes, value: minute.toString()};
  });

}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

.status--header {
  position: relative;
  padding-bottom: 40px;

  &__title {
    color: $primary;
    text-transform: uppercase;
    margin: 50px 0 0.541em 0;
    font-size: 14px;
  }

  &__info {
    display: flex;
    align-items: center;

    .v-icon {
      font-size: 20px;
    }

  }

}
</style>
