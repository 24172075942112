import Vue from "vue";
import VueCookie from "vue-cookies";
import App from "./App.vue";
import router from "./service/router";
import { store } from "@/store/index";
import axios from "axios";
import locale from "@eencloud/core-components/src/service/locale";
import vuetify from "./plugins/vuetify";
import auth from "@/service/auth";
import restapi from "@eencloud/core-components/src/service/CMApi";
import VueGtm from "vue-gtm";
import CoreComponents from "@eencloud/core-components";
import VueMask from 'v-mask'

/* eslint-disable no-undef */
if (config) {
  store.dispatch("setConfiguration", config);
}

Vue.config.productionTip = false;
Vue.use(CoreComponents);
Vue.use(VueCookie);
Vue.use(VueMask);


axios.interceptors.response.use(null, error => {
  if (error.response && error.response.status === 401) {
    store.dispatch("setAuthorized", auth.isAuthorized());
    if (auth.isLoggedIntoOtherApp()) {
      router.push({ path: "/firstlogout" });
    } else {
      auth.resetClient();
      window.location = auth.oauthUri();
    }
  } else if (error.message === "Network Error") {
    // console.error("Network Error");
  }

  return Promise.reject(error);
});

if (config["google-tag-manager"]["enabled"]) {
  Vue.use(VueGtm, {
    id: `${config["google-tag-manager"]["tracking-id"]}&gtm_auth=${config["google-tag-manager"]["gtm_auth"]}&gtm_preview=${config["google-tag-manager"]["gtm_preview"]}&gtm_cookies_win=x`,
    enabled: true,
    vueRouter: router
  });
}

router.beforeEach((to, from, next) => {
  restapi.cancelAll();
  // Only reroute if URI contains access token data
  if (
    to.query.hasOwnProperty("access_token") &&
    to.query.hasOwnProperty("expires_in")
  ) {
    auth.login(
      to.query.access_token,
      to.query.expires_in,
      to.query.skip_app_cookie
    );
    auth.getToken();
    let query = { ...to.query };
    delete query.a;
    delete query.access_token;
    delete query.expires_in;
    delete query.token_type;
    delete query.skip_app_cookie;
    delete query.state;
    router.replace({ path: to.path, query: query });
  }

  store.dispatch("setAuthorized", auth.isAuthorized());

  if (
    !store.getters.isAuthorized &&
    (!to.meta.hasOwnProperty("auth") || to.meta.auth)
  ) {
    if (to.path.indexOf("/settings") === 0) {
      // eslint-disable-next-line no-console
      console.log("Dispatching invalidtoken event");
      document.getElementById("app").dispatchEvent(new Event("invalidtoken"));
      next();
    } else if (auth.isLoggedIntoOtherApp()) {
      next({ path: "/firstlogout" });
    } else {
      auth.resetClient();
      window.location = auth.oauthUri();
    }
  } else {
    if (
      to.name === "subscriptions" &&
      (!store.getters.userSelfServiceManagement || !store.getters.isSuperUser)
    )
      next({ name: "UserDetails" });
    else if (to.path === "/") {
      next({ name: "UserDetails" });
    } else next();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // eslint-disable-next-line no-undef
  document.title = config.title;
  if (!store.getters.isAuthorized && to.path.indexOf("/settings") === 0) {
    document.getElementById("app").dispatchEvent(new Event("invalidtoken"));
  }
});

const i18n = locale.i18n;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
