<template>
  <v-container my-8 fluid>
    <v-layout row wrap>
      <v-flex xs8 offset-xs2 mb-4>
        <h2 class="text-center">
          Legal notices and conditions Eagle Eye Networks B.V.
        </h2>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 1 / Definitions</p>
        <ol type="a">
          <li>
            Customer: the natural person or legal person who has signed an
            agreement with Eagle Eye Networks B.V..
          </li>
          <li>
            Eagle Eye Networks B.V.: Eagle Eye Networks B.V. acting under the
            name Eagle Eye Networks B.V..
          </li>
          <li>
            Product: Equipment, software and/or adaptations to the software
            build by Eagle Eye Networks B.V., implementation of networks,
            helpdesk functions, online security services and other agreed
            activities and goods of an equal nature.
          </li>
          <li>
            System: computer equipment and related equipment with which Eagle
            Eye Networks B.V. grants access to the customer.
          </li>
          <li>
            Location: the physical surroundings in which the system of Eagle Eye
            Networks B.V. has been established and is connected to the network
            which can be accessed by the customer.
          </li>
          <li>
            Legislation camera surveillance: the laws for public camera
            surveillance
          </li>
          <li>
            Disk space: storage space which Eagle Eye Networks B.V. makes
            available for the customer, for example for playback of stored
            camera images.
          </li>
          <li>
            Internet site: one or more Internet-sites which are all property of
            Cameramanger.com and relate to the extensions and domain names owned
            by Eagle Eye Networks B.V..
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 2 / Application</p>
        <ol type="a">
          <li>
            By signing an agreement with Eagle Eye Networks B.V. the customer
            agrees with these general conditions and the terms of use policy of
            Eagle Eye Networks B.V.. All relations between Eagle Eye Networks
            B.V. and the customer are submitted to these general conditions and
            the terms of use policy.
          </li>
          <li>
            Exceptions and deviation other than these general conditions can
            only be made by a written declaration that is signed by a board
            member of Eagle Eye Networks B.V..
          </li>
          <li>
            These general conditions replace all previous general conditions.
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">
          Article 3 / Obligations Eagle Eye Networks B.V.
        </p>
        <ol type="a">
          <li>
            Eagle Eye Networks B.V. will make a reasonable effort to provide
            products on time as indicated in the agreement.
          </li>
          <li>
            Customer can not claim compensation for possible damage that arises
            from deliverance failure.
          </li>
          <li>
            Eagle Eye Networks B.V. will make a reasonable effort to provide its
            product as corresponded.
          </li>
          <li>
            Eagle Eye Networks B.V. has no right to make (video)data of the
            customer available for third parties, unless Eagle Eye Networks B.V.
            is obliged to disclose because of the law, or in case the customer
            acts, or it is suspected to act, in contrast with the Articles 5.b
            till 5.d of these general conditions and/or the terms or use policy.
          </li>
          <li>
            Eagle Eye Networks B.V. is free to make tariff modifications towards
            the customer. Customer has, if there is no alternative offer by
            Eagle Eye Networks B.V., the right to directly terminate the
            agreement with Eagle Eye Networks B.V., one month after the
            notification done by Eagle Eye Networks B.V..
          </li>
          <li>
            Eagle Eye Networks B.V. is obliged to take the following measures
            for all services of Eagle Eye Networks B.V.:
            <ol type="1">
              <li>
                Monitoring the servers and application processes (ping, http,
                application Eagle Eye Networks B.V.). More can be done on
                demand.
              </li>
              <li>
                Making modifications for extra security on the servers and
                applications.
              </li>
              <li>
                Engage in the recovery process when the servers and/or services
                go inactive.
              </li>
              <li>
                Management of all processes that require root access to the
                servers and applications. All other maintenance has to be done
                by the Customer. NOTE: In case the customer uses the facility
                "managed backup", then Eagle Eye Networks B.V. will daily make a
                copy of the customers data on a backup server. This copy will be
                stored standard for one day. From previous research it is known
                that the copies are not always reliable. Because of the large
                amount of copies that are made, Eagle Eye Networks B.V. is not
                able to check all copies. Therefore Eagle Eye Networks B.V. does
                not take any responsibility whether the backup has succeeded or
                not. (also see article 7a).
              </li>
            </ol>
          </li>
          <li>
            Conform the agreed SLA with customer Eagle Eye Networks B.V. takes
            care of solving problems that are related to its services
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 4 / Access Regulations</p>
        <ol type="a">
          <li>
            Customer has no access to the location where the servers are
            situated.
          </li>
          <li>
            Customer has no access to the application and the servers, except on
            user level. Customer can get access, but this has to be confirmed by
            Eagle Eye Networks B.V..
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 5 / Obligations Customer</p>
        <ol type="a">
          <li>
            Customer will notify Eagle Eye Networks B.V. in writing if a m
            utation occurs in his/her name, address, email address and bank
            number. This notification has to contain the date of change of the
            information. If customer does comply to this, the date of receiving
            will be taken as the entry date. Eagle Eye Networks B.V. is not
            responsible for information of customer that is not up to date and
            cannot be held responsible for consequences that rise from this.
          </li>
          <li>
            Customer shall follow the Netiquette. Furthermore, the customer
            shall not hinder other Customers or internet users or shall bring
            any damage to the system. It’s forbidden for the customer to start
            processes or programs that can be harmful for other customers or
            internet users.
          </li>
          <li>
            Customer hereby does not hold Eagle Eye Networks B.V. liable for all
            legal responsibilities concerning the stored Video Images,
            information and data.
          </li>
          <li>
            Furthermore, the customer is responsible for damage that has been
            made because of unprofessional practice, next to the obligations by
            law as stated in sub b and c.
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 6 / Exclusion of liability</p>
        <ol type="a">
          <li>
            Customer needs to make a recent backup of all relevant digital
            information before maintenance is done by the customer himself.
            Eagle Eye Networks B.V. can’t be held liable for digital information
            that is lost.
          </li>
          <li>
            Eagle Eye Networks B.V. can’t be held liable for damage on hardware
            and/or software as a consequence of software that is intended to be
            harmful, like computer viruses. Eagle Eye Networks B.V. is allowed
            to use antivirus programs to protect its software and hardware
            against infected data. Eagle Eye Networks B.V. also has the right to
            isolate or remove infected data from its servers.
          </li>
          <li>
            Eagle Eye Networks B.V.’s liability for indirect loss, including
            consequential loss, loss of profits, lost savings and loss caused by
            interruption of operation is excluded.
          </li>
          <li>
            Eagle Eye Networks B.V. is not liable for damage that is caused by
            third parties while using the product.
          </li>
          <li>
            Eagle Eye Networks B.V.’s total liability arising out of or in
            connection with the Agreement, Services, Additional Work, extra work
            and/or extra services shall be limited to making compensation for
            any direct loss up to the amount equal to the invoice value from
            Eagle Eye Networks B.V. payable by the Customer with respect to the
            Services, Additional Work, extra work and/or extra services that
            caused the damage incurred by the Customer.
          </li>
          <li>
            Eagle Eye Networks B.V. is not responsible for damage that is done
            by a third party.
          </li>
          <li>
            Eagle Eye Networks B.V. is not responsible for correct functioning
            of the equipment on the customer side and loss of login details.
          </li>
          <li>
            Eagle Eye Networks B.V. declares that the distant selling directive
            applies to the general terms. For the contents of this directive we
            refer to ‘Distant selling directive’
            (http://ec.europa.eu/consumers/cons_int/safe_shop/dist_sell/index_en.htm)
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 7 / Payment</p>
        <ol type="a">
          <li>
            Unless otherwise agreed upon all invoices shall be paid within
            fifteen days from invoice date without any discount.
          </li>
          <li>
            The Customer is not entitled to reduce or postpone payment to Eagle
            Eye Networks B.V. or to set of payment against any claim against
            Eagle Eye Networks B.V.
          </li>
          <li>
            If the Customer does not pay within the period agreed upon, it shall
            be in default solely by that period having expired without any
            notice of default or summons being required and interest for overdue
            payment shall be payable by the Customer at a rate of 1,5% per
            month, or in case the legal interest is higher, the legal interest.
            Furthermore, Eagle Eye Networks B.V. shall have the right to claim
            from the Customer, in addition to the principal and interest, any
            extrajudicial expenses incurred owing to payment not being made
            (timely). Extrajudicial expenses shall be payable by the Customer in
            any case when Eagle Eye Networks B.V. has engaged the assistance of
            a third party for collection. The rate for the expenses to be
            charged shall be equal to the collection rate applied by the
            Nederlandse Orde van Advocaten (the Bar in the Netherlands). The
            single fact that Eagle Eye Networks B.V. has engaged assistance of a
            third party demonstrates the size of and the obligation to pay the
            extrajudicial expenses. In case of default of payment Eagle Eye
            Networks B.V. may also postpone the assignment.
          </li>
          <li>
            In case Eagle Eye Networks B.V. has any doubt about the solvency of
            the Customer, Eagle Eye Networks B.V. shall have the right to
            suspend fulfilment of Eagle Eye Networks B.V. ‘s obligations - also
            if fulfilment thereof was already initiated - until the Customer has
            provided security to Eagle Eye Networks B.V.’s satisfaction for all
            obligations of the Customer ensuing from the Agreement.
          </li>
          <li>
            The payment engine of Buckaroo Online Payment Services is used for
            all online payments, one time and the recurring payments. For all
            technical details and security details concerning payments Eagle Eye
            Networks B.V. refers the customer to the third party mentioned above
            (www.buckaroo.nl). Eagle Eye Networks B.V. does not take any
            responsibility for the payment of the customer, except for the
            administrative process of the incoming payments and the
            communication of this towards the customer.
          </li>
          <li>
            Camermanager.com does not ask and/or save credit card details from
            the customer. The payment procedure is totally outsourced to third
            parties. These parties are certified to securely handle the online
            payment process of the customer.
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 8 / Proprietary rights</p>
        <ol type="a">
          <li>
            Eagle Eye Networks B.V. retains all rights, title and interest in
            and to any ideas, concepts, techniques, terminology and glossaries
            developed by Eagle Eye Networks B.V. and any software coding of any
            general utility developed by Eagle Eye Networks B.V..
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 9 / Intellectual property</p>
        <ol type="a">
          <li>
            Customer gets a non-transferable right to use the product delivered
            by Eagle Eye Networks B.V.
          </li>
          <li>
            Customer does not have the right to copy, sell, or resell services
            and products of Eagle Eye Networks B.V. unless agreed otherwise by a
            written consent by a board member of Eagle Eye Networks B.V..
          </li>
          <li>
            Eagle Eye Networks B.V. does not have the right to sell or copy
            (video)data of the customer which is stored on the servers of Eagle
            Eye Networks B.V. without a written consent of the customer, except
            for the cases explained in this agreement
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 10 / Force Majeure</p>
        <ol type="a">
          <li>
            In case Eagle Eye Networks B.V. cannot properly perform its
            obligations, in whole or in part, whether temporarily or
            permanently, based on the Agreement as a result of circumstances
            which are not at Eagle Eye Networks B.V.’s risk, including those
            circumstances mentioned in sub b), Eagle Eye Networks B.V. is
            entitled to rescind the Agreement with the Customer, without any
            compensation being due by Eagle Eye Networks B.V..
          </li>
          <li>
            Circumstances which are in no event at Eagle Eye Networks B.V.’s
            risk: strike or lock-out; labour shortage; transportation problems;
            disruption in the production process of Eagle Eye Networks B.V.;
            natural or nuclear disasters; war, war threat, and other
            circumstances which are beyond Eagle Eye Networks B.V.'s reasonable
            control.
          </li>
          <li>
            Eagle Eye Networks B.V. shall notify the Customer in writing of an
            event of force majeure and to what extent Eagle Eye Networks B.V.
            will be able to continue to perform the Agreement.
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 11 / Termination</p>
        <ol type="a">
          <li>
            Eagle Eye Networks B.V. may terminate this agreement forthwith
            customer fails to pay any sums due to Eagle Eye Networks B.V. as
            they fall due.
          </li>
          <li>
            Eagle Eye Networks B.V. may terminate this agreement immediately if
            customer breaches any of these terms and conditions, or if customer
            is a company and goes into insolvent liquidation, or if customer is
            a person and is declared bankrupt.
          </li>
          <li>
            On termination of this agreement Eagle Eye Networks B.V. shall be
            entitled immediately to block all access to the Eagle Eye Networks
            B.V. services and products and remove all (video)data located on its
            servers. Eagle Eye Networks B.V. will hold such data for a period of
            14 days and will allow customer to collect it, at its own expense
            (EUR150/hr), failing which Eagle Eye Networks B.V. shall be entitled
            to delete all such data.
          </li>
          <li>
            If customer wishes to terminate its account with Eagle Eye Networks
            B.V., the customer must notify Eagle Eye Networks B.V. specifically
            in writing, otherwise the account will be automatically renewed for
            the same subscription period and the customer will be liable for,
            and immediately invoiced upon the commencement of, such additional
            subscription period. Specifically, Eagle Eye Networks B.V. will not
            accept verbal instructions to terminate an account. All cancellation
            requests must be send to sales@Eagle Eye Networks B.V.. On receipt
            of customers cancellation request, Eagle Eye Networks B.V. will
            cancel the service at the first available opportunity.
          </li>
          <li>
            There are no refunds or credits, once an invoice is generated,
            unless an invoice for a service is generated after a cancellation
            requested is submitted.
          </li>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 12 / Dispute Resolution</p>
        <p>
          All Agreements between Eagle Eye Networks B.V. and the Customer shall
          be governed by the laws of the Netherlands. The competent Court at
          Amsterdam shall have exclusive jurisdiction with regard to all
          disputes arising between Eagle Eye Networks B.V. and the Customer,
          without prejudice to Eagle Eye Networks B.V.’s authority to summon the
          Customer before the competent Court in view of the Customer's
          residence.
        </p>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Article 13 / Miscellaneous</p>
        <ol type="a">
          <li>
            Eagle Eye Networks B.V. has the right to make changes in these
            general conditions. A change will be effective after fourteen days
            after notifying the customer. The customer can terminate the
            agreement when the changes become effective.
          </li>
          <li>
            A change in the board of Eagle Eye Networks B.V. or a change in the
            legal form of the company will not have any influence on this
            agreement
          </li>
          <li>
            If one of the articles from this agreement is declared to be
            excluded, all other articles will remain enforceable.
          </li>
          <li>
            The Terms of Use Policy of Eagle Eye Networks B.V. are connected to
            these general conditions.
          </li>
          <li>
            These English General terms & conditions are a translation from the
            Dutch Algemene Voorwaarden and are made with all best effort.
            However, in case of a dispute, the Dutch Algemene Voorwaarden will
            be effective. Eagle Eye Networks B.V. March, 2007
          </li>
        </ol>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Terms"
};
</script>
