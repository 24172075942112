<template>
  <v-app>
    <cookie-banner></cookie-banner>
    <een-header
      :isAuthorized="isAuthorized"
      :logo="logo"
      :appSwitcher="applications"
      :logoHeight="logoHeight"
      :logoWidth="logoWidth"
      :email="$store.getters.account ? $store.getters.email : 'an end user'"
      @logoClick="$router.push('/').catch(() => {})"
    >
      <template v-slot:account-menu>
        <div class="pt-3 pr-2">
          <een-account-menu
            :langSwitcher="true"
            :showWebApp="!$store.getters.supportAccess"
            :email="$store.getters.account ? $store.getters.email : null"
            :name="$store.getters.account ? $store.getters.username : null"
            @logout="logout"
          />
        </div>
      </template>
    </een-header>
    <v-main>
      <v-container fluid fill-height class="pb-0 pt-0">
        <router-view></router-view>
      </v-container>
    </v-main>
    <een-footer
      class="footer-billing"
      :terms="terms"
      :cookies="cookies"
      :privacyPolicy="privacyPolicy"
      :companyName="companyName"
    ></een-footer>
    <een-snack-bar
      :showing="$store.state.coreData.snackBar.showing"
      :timeout="$store.state.coreData.snackBar.timeout"
      :color="$store.state.coreData.snackBar.color"
      :text="$store.state.coreData.snackBar.text"
      :confirmation="$store.state.coreData.snackBar.confirmation"
      @closeSnackBar="$store.state.coreData.snackBar.showing = false"
    ></een-snack-bar>
  </v-app>
</template>

<script>
import CookieBanner from "@/components/CookieBanner";
import {
  reactive,
  computed,
  toRefs,
  onMounted
} from "vue";
import restapi from "@eencloud/core-components/src/service/CMApi";
import auth from "@/service/auth";
import locale from "@eencloud/core-components/src/service/locale";
import { store } from "@/store";
import { getConfiguration, changeFavicon, getImgUrl } from "./service/main";
import Theme from "@eencloud/core-components/src/service/themes";

export default {
  name: "App",
  setup() {
    const { logout, getToken } = useAuth();
    const state = reactive({
      loaded: false,
      token: computed(() => getToken()),
      isAuthorized: computed(() => store.getters.isAuthorized),
      logo: "",
      logoNavigation: "",
      theme: null,
      logoWidth: "",
      logoHeight: "",
      companyName: "",
      cookies: true,
      terms: true,
      privacyPolicy: true,
      applications: []
    });
    setupApi(state);
    initialLoad(state);
    loadTheme(state);
    loadLanguage();
    return {
      ...toRefs(state),
      logout
    };
  },
  components: {
    CookieBanner
  }
};

async function initialLoad(state) {
  if (state.isAuthorized) {
    await store.dispatch("loadAccountBase");
    await store.dispatch("loadAccountRights");
  }
  state.loaded = true;
  return;
}

function setupApi(state) {
  restapi.token = state.token;
  restapi.baseURL = store.getters.configuration.apiServer;
}

function loadTheme(state) {
  onMounted(() => {
    const config = getConfiguration();
    const themeName = config["theme"] ? config["theme"] : "Cameramanager";
    const theme = new Theme(themeName);
    const logo = theme.logo();
    if (logo) {
      if (logo.endsWith(".svg")) {
        state.logo = require(`@eencloud/core-components/src/assets/images/${logo}`);
      } else {
        state.logo = getImgUrl(theme.logo());
      }
    }
    const links = theme.links();
    state.logoHeight = theme.logoHeight();
    state.logoWidth = theme.logoWidth();
    state.companyName = theme.companyName();
    state.privacyPolicy = links.privacyPolicy;
    state.cookies = links.cookies;
    state.terms = links.terms;
    const favicon = theme.favicon();
    if (favicon) {
      changeFavicon(favicon);
    }

    state.applications = [
      {
        name: "Web App",
        link: links.webapp ? links.webapp : config.webappURL,
        icon: "globe",
        show: true
      },
      {
        name: "Download Portal",
        link: links.downloadportal
          ? links.downloadportal
          : config.downloadportalURL,
        icon: "download",
        show: links.showDownloadPortal
      },
      {
        name: "My Account",
        link: links.myaccount ? links.myaccount : config.myaccountURL,
        icon: "user",
        show: true
      },
      {
        name: "Dealer Dashboard",
        link: links.dealerdashboard ? links.dealerdashboard : config.dealerURL,
        icon: "list-alt",
        show: links.showdealerdashboard
      }
    ];
  });
}

function loadLanguage() {
  let lang = window.$cookies.get("lang") || "en";
  if (!store.getters.configuration.crowdinIntegration && lang === "aa")
    lang = "en";
  locale.setLanguage(lang);
}

function useAuth() {
  function getToken() {
    return auth.getToken();
  }

  function logout() {
    auth.logout();
  }

  return {
    logout,
    getToken
  };
}
</script>

<style lang="scss" scoped>
@import "assets/styles/main";
</style>
