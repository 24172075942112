<template>
  <v-card>
    <v-form ref="form" v-model="isFormValid">
      <v-container class="px-8 py-5" fluid>
        <v-layout row wrap>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Email") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              type="email"
              :label="$t('Email address')"
              v-model="currentEmail"
              :rules="rules.currentEmail"
              autocomplete="username"
              :solo="$vuetify.breakpoint.smAndUp"
              flat
              dense
              :background-color="
                $vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''
              "
            ></v-text-field>
          </v-flex>
          <v-flex sm12 class="hidden-xs-only" pb-5
            ><v-divider></v-divider
          ></v-flex>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Password") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              :label="$t('Current password')"
              v-model="currentPassword"
              :rules="rules.currentPassword"
              type="password"
              autocomplete="new-password"
              :solo="$vuetify.breakpoint.smAndUp"
              flat
              dense
              :background-color="
                $vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''
              "
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-btn
              color="primary"
              :disabled="!isFormValid"
              @click="changeEmail"
              >{{ $t("Change email") }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { reactive, toRefs } from "vue";
import { minLength, maxLength, email } from "@/common/formValidators";
import { t } from "@eencloud/core-components/src/service/locale";
import { store } from "@/store";

export default {
  name: "ChangeEmail",
  setup() {
    const state = useComponentState();
    const { changeEmail } = useChangeEmail(state);
    const rules = formRules(state);

    return {
      ...toRefs(state),
      rules,
      changeEmail
    };
  }
};

function useComponentState() {
  const state = reactive({
    isFormValid: false,
    currentEmail: store.getters.email,
    currentPassword: null,
    form: null
  });

  return state;
}

function useChangeEmail(state) {
  function changeEmail() {
    let accountBase = {
      email: state.currentEmail,
      password: state.currentPassword
    };

    store.dispatch("setAccountEmail", accountBase).then(() => {
      state.currentPassword = null;
      state.form.resetValidation();
    });
  }
  return { changeEmail };
}

function formRules() {
  const rules = {
    currentEmail: [
      value => !!value || t("Please enter your email address"),
      value =>
        (minLength(value, 8) && maxLength(value, 128)) ||
        t("Email should be 8-128 characters"),
      value => email(value) || t("Please enter a valid email address")
    ],
    currentPassword: [
      value => !!value || t("Please enter your curent password")
    ]
  };
  return rules;
}
</script>

<style lang="scss" scoped></style>
