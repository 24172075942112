<template>
  <div class="settings--container__width">
    <!-- <Subscription></Subscription> -->
    <Subscription
      v-if="hasSubscriptionSelfService"
      :subscriptions="subscriptions"
      :resellerId="255"
      :account="account"
      userId="self"
      :currentSubscription="currentSubscription"
      :nextSubscription="nextSubscription"
      @changed="loadData"
    ></Subscription>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { store } from "@/store";
import Subscription from "@/components/settings/Subscription";
import restapi from "@eencloud/core-components/src/service/CMApi";

export default {
  name: "Subscriptions",
  components: {
    Subscription
  },
  setup() {
    const data = reactive({
      isLoading: false,
      hasSubscriptionSelfService: computed(
        () => store.getters.userSelfServiceManagement
      ),
      userId: computed(() => store.getters.account.userId),
      currentSubscription: computed(() => {
        let sub = {};
        if (data.subscription && data.subscriptions) {
          const sId = data.subscription.subscriptionPackageId;
          sub = data.subscriptions.find(function(s) {
            return s.subscriptionId === sId;
          }) || {
            available: false,
            valid: false,
            name: "Invalid subscription"
          };
        }
        if (data.subscription) {
          Object.assign(sub, data.subscription);
        }
        return sub;
      }),
      nextSubscription: computed(() => {
        let sub = {};
        if (
          data.subscription &&
          data.subscription.nextSubscriptionPackageId !== -1 &&
          data.subscriptions
        ) {
          const sId = data.subscription.nextSubscriptionPackageId;
          sub = data.subscriptions.find(function(s) {
            return s.subscriptionId === sId;
          }) || {
            available: false,
            valid: false,
            name: "Invalid subscription"
          };
        }
        return sub;
      }),
      active: true,
      user: null,
      subscription: null,
      reseller: null,
      distributor: null,
      subscriptions: [],
      users: null,
      modules: {},
      resellerId: 255,
      account: {}
    });
    const { loadData } = useLoadData(data);
    loadData();
    return { ...toRefs(data), loadData };
  }
};

function useLoadData(data) {
  async function loadData() {
    try {
      data.isLoading = true;

      data.subscription = await restapi.getAccountSubscription("self");

      data.subscriptions = await restapi.getAccountSubscriptions("self");

      data.isLoading = false;
    } catch (error) {
      throw error;
    }
  }

  return { loadData };
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

</style>
