import { render, staticRenderFns } from "./Cookies.vue?vue&type=template&id=77b626fb&scoped=true"
import script from "./Cookies.vue?vue&type=script&lang=js"
export * from "./Cookies.vue?vue&type=script&lang=js"
import style0 from "./Cookies.vue?vue&type=style&index=0&id=77b626fb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b626fb",
  null
  
)

export default component.exports