import Vue from "vue";
import Vuex from "vuex";

import coreData from "./modules/core";
import account from "./modules/account";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    coreData,
    account
  }
});
