<template>
  <v-card>
    <v-form ref="form" v-model="isFormValid">
      <v-container class="px-8 py-5" fluid>
        <v-layout row wrap>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Current password") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              :label="$t('Current password')"
              v-model="currentPassword"
              :rules="rules.currentPassword"
              type="password"
              :solo="$vuetify.breakpoint.smAndUp"
              flat
              dense
              :background-color="
                $vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''
              "
            ></v-text-field>
          </v-flex>
          <v-flex sm12 class="hidden-xs-only" pb-5
            ><v-divider></v-divider
          ></v-flex>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("New password") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              :label="$t('New password')"
              v-model="newPassword"
              :rules="rules.newPassword"
              type="password"
              :solo="$vuetify.breakpoint.smAndUp"
              flat
              dense
              :background-color="
                $vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''
              "
            ></v-text-field>
          </v-flex>
          <v-flex sm12 class="hidden-xs-only" pb-5
            ><v-divider></v-divider
          ></v-flex>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Confirm new password") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              :label="$t('Confirm new password')"
              v-model="confirmNewPassword"
              :rules="rules.confirmNewPassword"
              type="password"
              :solo="$vuetify.breakpoint.smAndUp"
              flat
              dense
              :background-color="
                $vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''
              "
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-btn
              color="primary"
              :disabled="!isFormValid"
              @click="changePassword"
              >{{ $t("Change password") }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { reactive, watch, toRefs } from "vue";
import { minLength, maxLength, password } from "@/common/formValidators";
import { t } from "@eencloud/core-components/src/service/locale";
import restapi from "@eencloud/core-components/src/service/CMApi";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import auth from "@/service/auth";

export default {
  name: "ChangePassword",
  setup() {
    const state = useComponentState();
    watchPasswordMatch(state);
    const { changePassword } = useChangePassword(state);
    const rules = formRules(state);

    return {
      ...toRefs(state),
      rules,
      changePassword
    };
  }
};

function useComponentState() {
  const state = reactive({
    isFormValid: false,
    currentPassword: null,
    newPassword: null,
    confirmNewPassword: null,
    newPasswordMatches: false,
    form: null
  });

  return state;
}

function useChangePassword(state) {
  function changePassword() {
    if (state.newPassword === state.confirmNewPassword) {
      restapi
        .isPasswordValid(state.currentPassword)
        .then(() => {
          return restapi.setUserPassword(state.newPassword);
        })
        .then(() => {
          auth.cleanOnLogout();
          setTimeout(function() {
            window.location.reload();
          }, 2000);
          store.dispatch("toastMessage", {
            showing: true,
            text: t("The Password was succcessfully changed."),
            timeout: 2000,
            color: "primary"
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          store.dispatch("toastMessage", {
            showing: true,
            text: getMessageFromError(error, 'UPDATE_PASSWORD'),
            timeout: -1,
            color: "error"
          });
        });
    }
  }
  return { changePassword };
}

function watchPasswordMatch(state) {
  watch(
    [() => state.newPassword, () => state.confirmNewPassword],
    ([newPassword, confirmNewPassword], prev) => {
      state.newPasswordMatches = newPassword === confirmNewPassword;
      if (prev !== undefined) state.form.validate();
    }
  );
}

function formRules(state) {
  const rules = {
    currentPassword: [
      value => !!value || t("Please enter your current password")
    ],
    newPassword: [
      value => !!value || t("Please enter a new password"),
      value => password(value) || t("Password contains invalid characters"),
      value =>
        (minLength(value, 8) && maxLength(value, 32)) ||
        t("Password should be 8-32 characters")
    ],
    confirmNewPassword: [
      value => !!value || t("Please enter your new password again"),
      () => state.newPasswordMatches || t("Password does not match")
    ]
  };
  return rules;
}
</script>

<style lang="scss" scoped>
form.v-form.larger {
  font-size: 14px;
  input {
    font-size: inherit;
  }
}
</style>
