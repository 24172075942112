<template>
  <v-card>
    <v-form v-model="isFormValid">
      <v-container class="px-8 py-5" fluid>
        <v-layout row wrap>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("First name") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              :label="$vuetify.breakpoint.smAndUp ? '' : $t('First name')"
              v-model="firstname"
              :rules="rules.firstname"
              :solo="$vuetify.breakpoint.smAndUp"
              flat
              dense
              :disabled="$store.getters.preventEditUserDetails"
              :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
            ></v-text-field>
          </v-flex>
          <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Last name") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              :label="$vuetify.breakpoint.smAndUp ? '' : $t('Last name')"
              v-model="lastname"
              :rules="rules.lastname"
              :solo="$vuetify.breakpoint.smAndUp"
              flat
              dense
              :disabled="$store.getters.preventEditUserDetails"
              :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
            ></v-text-field>
          </v-flex>
          <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Telephone") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              :label="$vuetify.breakpoint.smAndUp ? '' : $t('Telephone')"
              v-model="telephone"
              :rules="rules.telephone"
              :solo="$vuetify.breakpoint.smAndUp"
              :disabled="$store.getters.preventEditUserDetails"
              flat
              dense
              :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
            ></v-text-field>
          </v-flex>
          <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Company") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              :label="$vuetify.breakpoint.smAndUp ? '' : $t('Company')"
              v-model="company"
              :rules="rules.company"
              :solo="$vuetify.breakpoint.smAndUp"
              :disabled="$store.getters.preventEditUserDetails"
              flat
              dense
              :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
            ></v-text-field>
          </v-flex>
          <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Country") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-autocomplete
              class="mb-6"
              v-model="country"
              :items="countries"
              item-value="value"
              item-text="text"
              :label="$vuetify.breakpoint.smAndUp ? '' : $t('Country')"
              :solo="$vuetify.breakpoint.smAndUp"
              :disabled="$store.getters.preventEditUserDetails"
              flat
              dense
              hide-details
              :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
            ></v-autocomplete>
          </v-flex>
          <template v-if="false">
            <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
            <v-flex sm6 class="hidden-xs-only">
              <p class="subheading my-2">{{ $t("Language") }}</p>
            </v-flex>
            <v-flex sm6 xs12>
              <v-autocomplete
                class="mb-6"
                v-model="language"
                :items="languages"
                item-value="value"
                item-text="text"
                :label="$vuetify.breakpoint.smAndUp ? '' : $t('Language')"
                :solo="$vuetify.breakpoint.smAndUp"
                flat
                :disabled="$store.getters.preventEditUserDetails"
                dense
                hide-details
                :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
              ></v-autocomplete>
            </v-flex>
          </template>
          <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
          <v-flex sm6 class="hidden-xs-only">
            <p class="subheading my-2">{{ $t("Time zone") }}</p>
          </v-flex>
          <v-flex sm6 xs12>
            <v-autocomplete
              class="mb-6"
              v-model="timezone"
              :items="timeZones"
              item-value="value"
              item-text="text"
              :label="$vuetify.breakpoint.smAndUp ? '' : $t('Time')"
              :solo="$vuetify.breakpoint.smAndUp"
              flat
              :disabled="$store.getters.preventEditUserDetails"
              dense
              hide-details
              :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
            >
              <template v-slot:item="data">
                {{ data.item + addOffset(data.item) }}
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <v-btn
              v-if="!$store.getters.preventEditUserDetails"
              color="primary"
              :disabled="!isDirty || !isFormValid"
              @click="savePersonalDetails"
              >{{ $t("Save changes") }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { reactive, computed, onMounted, toRefs } from "vue";
import { minLength, maxLength, phoneNumber, name } from "@/common/formValidators";
import { t } from "@eencloud/core-components/src/service/locale";
import { store } from "@/store";
import { findTimeZone, getUTCOffset } from "timezone-support";

export default {
  name: "PersonalDetails",
  setup() {
    const state = useComponentState();

    initialLoad(state);

    const { savePersonalDetails, addOffset } = usePersonalDetails(state);
    const rules = formRules();

    return {
      ...toRefs(state),
      rules,
      savePersonalDetails,
      addOffset,
    };
  },
};

function useComponentState() {
  const state = reactive({
    isFormValid: false,
    initValues: {
      firstname: null,
      lastname: null,
      telephone: null,
      company: null,
      country: null,
      language: null,
      timezone: null,
    },
    firstname: null,
    lastname: null,
    telephone: null,
    company: null,
    country: null,
    language: null,
    timezone: null,
    isDirty: computed(() => {
      return (
        state.firstname !== state.initValues.firstname ||
        state.lastname !== state.initValues.lastname ||
        state.telephone !== state.initValues.telephone ||
        state.company !== state.initValues.company ||
        state.country !== state.initValues.country ||
        state.language !== state.initValues.language ||
        state.timezone !== state.initValues.timezone
      );
    }),
    countries: computed(() => store.getters.countries),
    languages: computed(() => store.getters.languages),
    timeZones: computed(() => store.getters.timeZones.sort()),
    timeStamp: null,
  });

  return state;
}

async function initialLoad(state) {
  onMounted(() => {
    state.initValues.firstname = store.getters.firstname;
    state.firstname = store.getters.firstname;

    state.initValues.lastname = store.getters.lastname;
    state.lastname = store.getters.lastname;

    state.initValues.telephone = store.getters.telephone;
    state.telephone = store.getters.telephone;

    state.initValues.company = store.getters.company;
    state.company = store.getters.company;

    state.initValues.country = store.getters.country;
    state.country = store.getters.country;

    state.initValues.language = store.getters.language;
    state.language = store.getters.language;

    state.initValues.timezone = store.getters.timeZone;
    state.timezone = store.getters.timeZone;
  });
}

function usePersonalDetails(state) {
  function savePersonalDetails() {
    if (state.firstname !== state.initValues.firstname || state.lastname !== state.initValues.lastname) {
      let accountBase = {
        firstName: state.firstname,
        lastName: state.lastname,
      };
      store.dispatch("setAccountName", accountBase).then(() => {
        state.initValues.firstname = state.firstname;
        state.initValues.lastname = state.lastname;
      });
    }

    if (
      state.telephone !== state.initValues.telephone ||
      state.company !== state.initValues.company ||
      state.country !== state.initValues.country
    ) {
      let accountDetails = {
        language: state.language,
        companyName: state.company,
        country: state.country ? state.country : null,
        phone: state.telephone,
      };
      store.dispatch("setAccounDetails", accountDetails).then(() => {
        state.initValues.telephone = state.telephone;
        state.initValues.company = state.company;
        state.initValues.country = state.country;
        state.initValues.language = state.language;
      });
    }

    if (state.timezone !== state.initValues.timezone) {
      store.dispatch("setAccountTimeZone", state.timezone).then(() => {
        state.initValues.timezone = state.timezone;
      });
    }
  }

  function addOffset(timezone) {
    const offset = getOffset(timezone);
    const hours = offset / 60;
    const minutes = offset % 60;
    if (hours > 0) return " ( -" + hours + (minutes ? `:${Math.abs(minutes)}` : "") + " )";
    else return " ( +" + Math.floor(Math.abs(hours)) + (minutes ? `:${Math.abs(minutes)}` : "") + " )";
  }

  function getOffset(timeZone) {
    const foundTimeZone = findTimeZone(timeZone);
    const date = new Date();
    const timeZoneOffset = getUTCOffset(date, foundTimeZone);
    return timeZoneOffset.offset;
  }

  return { savePersonalDetails, addOffset };
}

function formRules() {
  const rules = {
    firstname: [
      (value) => name(value) || t("Please enter a valid name"),
      (value) => !!value || t("First name is required"),
      (value) => (minLength(value, 2) && maxLength(value, 32)) || t("First name should be 2-32 characters"),
    ],
    lastname: [
      (value) => name(value) || t("Please enter a valid name"),
      (value) => !!value || t("Last name is required"),
      (value) => (minLength(value, 2) && maxLength(value, 32)) || t("Last name should be 2-32 characters"),
    ],
    telephone: [
      (value) => phoneNumber(value) || t("This is not a valid phone number"),
      (value) => (minLength(value, 2) && maxLength(value, 64)) || t("Telephone should be 2-64 characters"),
    ],
    company: [
      (value) => name(value) || t("Please enter a valid Company name"),
      (value) => (minLength(value, 2) && maxLength(value, 255)) || t("Company name should be 2-255 characters"),
    ],
  };
  return rules;
}
</script>

<style lang="scss" scoped>
form.v-form.larger {
  font-size: 14px;
  input {
    font-size: inherit;
  }
}
</style>
