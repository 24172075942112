<template>
  <v-container fluid v-if="!isAuthorized">
    <v-layout row wrap class="hidden-xs-and-down">
      <v-flex xs8 offset-xs2 pa-0>
        <p class="headline">
          {{ $t("You are now logged out. To login again click") }}
          <router-link to="/">{{ $t("here") }}</router-link
          >.
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// Because of a Chrome issue we needed to implement this special logout component
import { store } from "@/store";
import logout from "@/components/common/logout";
import { reactive, computed, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      isAuthorized: computed(() => store.getters.isAuthorized)
    });
    logout();
    return { ...toRefs(state) };
  }
};
</script>
