<template>
  <v-snackbar
    id="cookie-banner"
    v-model="showCookieBanner"
    top
    color="primary"
    :timeout="timeout"
  >
    {{
      $t(
        "We use cookies on this site to enhance your user experience. By viewing this page you are giving your consent for us to set cookies."
      )
    }}
    <router-link  target="_blank"  to="/cookies"><span> {{
      $t("Learn more")
    }} </span></router-link>
    <v-btn color="white" icon @click="close"><v-icon>fas fa-times</v-icon></v-btn>
  </v-snackbar>
</template>

<script>
const COOKIE_KEY = "cookie_warning_banner";

export default {
  name: "CookieBanner",
  data() {
    return {
      timeout: -1,
      showCookieBanner: false
    };
  },
  mounted() {
    let value = window.$cookies.get(COOKIE_KEY) || "0";
    this.showCookieBanner = value !== "1";
  },
  methods: {
    close() {
      window.$cookies.set(COOKIE_KEY, 1, "1y");
      this.showCookieBanner = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.notification {
  padding: 10px 40px 10px 12px;
  color: white;
  font-size: 12px;
  position: fixed;
  z-index: 20;
  max-width: 850px;
  left: 0;
  right: 0;
  margin: 5px auto;
}

@media screen and (max-width: 768px) {
  .notification {
    margin: 5px 15px;
    bottom: 10px;
  };
}
.v-application a {
  color: white;
}
</style>
