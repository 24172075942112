/* eslint-disable no-console */
import restapi from "@eencloud/core-components/src/service/CMApi";

const state = {
  configuration: null,
  countries: [],
  languages: [],
  timeZones: [],
  snackBar: {},
  isAuthorized: false
};

const getters = {
  configuration: state => {
    return state.configuration;
  },
  countries: state => {
    return state.countries;
  },
  languages: state => {
    return state.languages;
  },
  timeZones: state => {
    return state.timeZones;
  },
  isAuthorized: state => {
    return state.isAuthorized;
  }
};

const mutations = {
  SET_CONFIGURATION: (state, configuration) => {
    state.configuration = configuration;
  },
  SET_COUNTRIES: (state, countryList) => {
    state.countries = countryList;
  },
  SET_LANGUAGES: (state, languageList) => {
    state.languages = languageList;
  },
  CALL_TOAST: (state, toastMessage) => {
    state.snackBar = toastMessage;
  },
  SET_AUTHORIZED: (state, isAuthorized) => {
    state.isAuthorized = isAuthorized;
  },
  SET_TIMEZONES: (state, newValue) => {
    state.timeZones = newValue;
  }
};

const actions = {
  setConfiguration({ commit }, config) {
    commit("SET_CONFIGURATION", config);
  },
  loadCountries({ commit }) {
    return restapi
      .getCountries()
      .then(data => commit("SET_COUNTRIES", data))
      .catch(e => console.error(e));
  },
  loadLanguages({ commit }) {
    return restapi
      .getLanguages()
      .then(data => commit("SET_LANGUAGES", data))
      .catch(e => console.error(e));
  },
  toastMessage({ commit }, message) {
    commit("CALL_TOAST", message);
  },
  setAuthorized({ commit }, data) {
    commit("SET_AUTHORIZED", data);
  },
  async getTimeZones({ commit }) {
    try {
      const timeZones = await restapi.getTimeZones()
      commit("SET_TIMEZONES", timeZones)
    } catch (error) {
      console.log(error)
    }

  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
