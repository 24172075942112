<template>
  <v-card class="mt-8">
    <v-form v-model="isFormValid" ref="form">
      <v-card-text>
        <v-row>
          <v-col>
            <v-img contain max-width="250px" style="box-sizing: border-box;" :src="qrCode" alt="MFA QR code"></v-img>
          </v-col>
          <v-col class="pt-12">
            <p class="font-weight-bold">
              {{ $t("Use the QR code") }}:
            </p>
            <p class="mb-2">
              1. {{ $t("Install the Authenticator app on your phone. You can use any Authenticator app e.g Google (Android / iOS) or Microsoft (Android / iOS).")}}
            </p>
            <ul>
              <li>
                Google Android - <a target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google
                  Authenticator - Google Play </a>
              </li>
              <li>
                Google iOS - <a target="_blank"
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605">Google
                  Authenticator - App Store (apple.com) </a>
              </li>
              <li>
                Microsoft Android - <a target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US">Microsoft
                  Authenticator - Google Play </a>
              </li>
              <li>
                Microsoft iOS - <a target="_blank"
                  href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">Microsoft
                  Authenticator - App Store (apple.com)</a>
              </li>
            </ul>

            <p class="mb-2 mt-2">
              2. {{ $t("Run the application") }}
            </p>
            <p class="mb-2">
              3. {{ $t("Scan the QR code and add it using the Google/Microsoft Authenticator app") }}
            </p>
            <p class="mb-2">
              4. {{ $t("Enter the code generated by the app") }}
            </p>
          </v-col>

        </v-row>
        <v-col cols="6">
          <v-text-field v-mask="'######'" :rules="[rules.required, rules.counter]"
            :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''" :solo="$vuetify.breakpoint.smAndUp"
            :label="$t('Authenticator Code')" v-model="mfaCode" flat dense>
          </v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions class="ml-6 mb-2">

        <v-btn depressed color="primary" class="mr-4" @click="updateMfa(true)" :disabled="enabled">
          {{ $t("Enable") }}
        </v-btn>

        <v-btn depressed color="primary" @click="updateMfa(false)" :disabled="!enabled">
          {{ $t("Disable") }}
        </v-btn>

      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import restapi from "@eencloud/core-components/src/service/CMApi";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

export default {
  name: "Tfa",
  data() {
    return {
      enabled: false,
      mfaCode: "",
      qrCode: "",
      isFormValid: false,
      rules: {
        counter: value => (!this.isMfaCodeEmpty() ? value.length >= 6 || this.$t('6 characters are required') : true),
        required: value => (!this.isMfaCodeEmpty() ? !!value || this.$t('Required.') : true),
      }
    };
  },
  methods: {
    updateMfa(status) {
      this.$refs.form.validate();
      if (!this.isMfaCodeEmpty() && this.isFormValid) {
        let that = this;
        restapi
          .setUserMfa(status, this.mfaCode)
          .then(() => {
            that.enabled = status;
            store.dispatch("toastMessage", {
              showing: true,
              text: that.enabled ? that.$t("MFA has been successfully enabled") : that.$t("MFA has been successfully disabled"),
              timeout: 2000,
              color: "primary"
            });
          })
          .catch((error) => {
            console.log(error);
            store.dispatch("toastMessage", {
              showing: true,
              text: getMessageFromError(error, "SET_MFA_DETAILS"),
              timeout: -1,
              color: "error"
            });
          })
          .finally(() => {
            that.clearMfaCode();
          });
      }
    },
    loadMfaData() {
      restapi
        .getUserMfa()
        .then((data) => {
          this.qrCode = data.qrCode;
          this.enabled = data.enabled;
        })
        .catch(() => {
          store.dispatch("toastMessage", {
            showing: true,
            text: getMessageFromError(error, "GET_MFA_DETAILS"),
            timeout: -1,
            color: "error"
          });
        });
    },
    isMfaCodeEmpty() {
      return this.mfaCode.trim() === "";
    },
    clearMfaCode() {
      this.mfaCode = "";
    },
  },
  mounted() {
    this.loadMfaData();
  },
};
</script>

