<template>
  <v-row>
    <v-col>
      <p class="text-uppercase pl-0 mt-4 mb-2">
        {{ $t("Personal details") }}
      </p>
      <personal-details></personal-details>
    </v-col>
    <v-col v-if="!$store.getters.preventEditUserDetails">
      <p class="text-uppercase pl-0 mt-4 mb-2">
        {{ $t("Change email") }}
      </p>
      <change-email></change-email>
      <p class="text-uppercase pl-0 mt-8 mb-2">
        {{ $t("Change password") }}
      </p>
      <change-password></change-password>
    </v-col>
  </v-row>
</template>

<script>
import { reactive, toRefs } from "vue";
import { store } from "@/store";
import restapi from "@eencloud/core-components/src/service/CMApi";
import PersonalDetails from "@/components/settings/PersonalDetails";
import ChangeEmail from "@/components/settings/ChangeEmail";
import ChangePassword from "@/components/settings/ChangePassword";

export default {
  name: "Settings",
  setup() {
    const state = reactive({
      loaded: false,
    });

    initialLoad(state);

    return { ...toRefs(state) };
  },
  components: {
    PersonalDetails,
    ChangeEmail,
    ChangePassword,
  },
};

async function initialLoad(state) {
  restapi.cancelAll();
  await store.dispatch("loadAccountBase");
  await store.dispatch("loadAccountStatus");
  await store.dispatch("loadAccountDetails");
  await store.dispatch("loadCountries");
  await store.dispatch("loadLanguages");
  await store.dispatch("getAccountTimeZone");
  await store.dispatch("getTimeZones");
  state.loaded = true;
  return;
}
</script>

<style lang="scss" scoped>
@media (min-width: 1904px) {
  .settings-container-inner {
    max-width: 1185px;
  }
}
</style>
