export function getConfiguration() {
  /* eslint-disable no-undef */
  return config;
}

export function changeFavicon(link) {
  let favicon = document.getElementById("favicon");
  if (favicon !== null) {
    favicon.href = "/" + link;
  }
}

export function getImgUrl(img) {
  var pixelRatio = getPixelRatio();

  var images = getImagesContext(pixelRatio);

  return images("./" + img);
}

export function getPixelRatio() {
  return window.devicePixelRatio;
}

export function getImagesContext(pixelRatio) {
  // fallback is to scale 1.0
  var images = require.context(
    "../../node_modules/@eencloud/core-components/src/assets/images",
    false,
    /\.jpg$|\.png$|.gif$/
  );

  if (pixelRatio > 1 && pixelRatio < 2) {
    images = require.context(
      "../../node_modules/@eencloud/core-components/src/assets/images/hdpi150",
      false,
      /\.jpg$|\.png$|.gif$/
    );
  } else if (pixelRatio >= 2 && pixelRatio < 3) {
    images = require.context(
      "../../node_modules/@eencloud/core-components/src/assets/images/hdpi200",
      false,
      /\.jpg$|\.png$|.gif$/
    );
  } else if (pixelRatio >= 3) {
    images = require.context(
      "../../node_modules/@eencloud/core-components/src/assets/images/hdpi300",
      false,
      /\.jpg$|\.png$|.gif$/
    );
  }

  return images;
}
