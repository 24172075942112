<template>
  <div>
    <div class="choose-subscription-block" v-if="hasAvailableSubscriptions">
      <div class="body" v-show="!isCustomSubscription">
        <div class="subscriptions-type-selection item-block">
          <p class="title">{{ $t("Cloud storage or live view only") }}</p>
          <two-sides-switch
            id="change-cloud-storage-button"
            :initValue="!cloudStorage"
            v-on:switch-changed="subscriptionTypeChanged"
            :leftLabel="subscriptionTypeLeftLabel"
            :rightLabel="subscriptionTypeRightLabel"
          ></two-sides-switch>
        </div>
        <div v-show="cloudStorage" class="video-quality-block item-block">
          <p class="title">{{ $t("Video quality") }}</p>
          <div class="buttons">
            <v-btn
              v-for="(item, index) in videoQualities"
              :key="`i-${index}`"
              :id="getIdForVideoQualityButton(item)"
              @click="setVideoQuality(item.value)"
              :class="[{ selected: isVideoQualityButtonSelected(item.value) }]"
              >{{ item.label }}</v-btn
            >
          </div>
        </div>
        <div v-show="cloudStorage" class="days-of-storage-block item-block">
          <p class="title">{{ $t("Days of storage") }}</p>
          <div class="buttons">
            <v-btn
              v-for="(item, index) in daysOfStorage"
              :key="`i-${index}`"
              :id="getIdForVideoQualityButton(item)"
              @click="setDaysOfStorage(item.value)"
              :class="[{ selected: isDayButtonSelected(item.value) }]"
              >{{ item.label }}</v-btn
            >
          </div>
        </div>
        <div class="number-of-cameras-block item-block">
          <p class="title">{{ $t("Number of cameras") }}</p>
          <number-spinner
            id="change-number-of-cameras-button"
            v-on:value-changed="cameraNumberChanged"
            :initValue="numberOfCameras"
            :min="1"
            :max="maxCamerasNumber"
          ></number-spinner>
        </div>
        <div class="sku-block item-block">
          <p class="title">{{ $t("SKU") }}</p>
          <p class="content">{{ getSubscriptionsSKU }}</p>
        </div>
      </div>
      <div v-show="isCustomSubscription" class="custom-subscriptions">
        <div class="field">
          <div class="has-float-label">
            <v-select
              id="select-custom-subscription-name-input"
              @change="onSelectCustomSubscriptionName"
              :label="$t('Subscription')"
              :items="customSubscriptions"
              v-model="selectedSubscriptionName"
              item-text="name"
              item-value="name"
              return-object
              dense
            >
            </v-select>
            <v-select
              id="select-custom-subscription-cameras-input"
              @change="onSelectCustomSubscriptionCameras"
              :label="$t('Number of cameras')"
              :items="customSubscriptionCameras"
              v-model="selectedSubscriptionCameras"
              item-text="value"
              item-value="value"
              return-object
              dense
            >
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="has-text-centered" v-else>
      <p class="has-text-error">
        {{ $t("There are no available subscriptions to choose from.") }}
      </p>
      <p>{{ $t("Please contact support") }}</p>
    </div>
  </div>
</template>

<script>
import TwoSidesSwitch from "@/components/base/TwoSidesSwitch";
import NumberSpinner from "@/components/base/NumberSpinner";

const CM_LIVE_ONLY_GROUP_NAME = "CM10";
const CM_LIVE_ONLY_DAYS_STORAGE = "0";
const CM_LIVE_ONLY_MAX_CAMERAS = 200;
const INITIAL_MAX_CAMERAS = 10;
const INITIAL_DAYS_STORAGE = 7;
const INITIAL_GROUP_NAME = "SD1";

export default {
  name: "ChooseSubscription",
  props: ["subscription", "subscriptions"],
  data() {
    return {
      videoQuality: null,
      daysOfStorageNumber: null,
      cloudStorage: true,
      numberOfCameras: null,
      maxCamerasNumber: null,
      subscriptionTypeLeftLabel: this.$t("Cloud storage"),
      subscriptionTypeRightLabel: "Camera management / live only",
      videoQualities: [
        {
          value: "SD1",
          label: this.$t("subscription_type_sd1"),
        },
        {
          value: "HD1",
          label: this.$t("subscription_type_hd1"),
        },
        {
          value: "HD2",
          label: this.$t("subscription_type_hd2"),
        },
      ],
      daysOfStorage: [
        {
          value: 7,
          label: this.$t("days_of_storage_7"),
          maxCameras: 200,
        },
        {
          value: 14,
          label: this.$t("days_of_storage_14"),
          maxCameras: 200,
        },
        {
          value: 30,
          label: this.$t("days_of_storage_30"),
          maxCameras: 200,
        },
        {
          value: 60,
          label: this.$t("days_of_storage_60"),
          maxCameras: 120,
        },
        {
          value: 90,
          label: this.$t("days_of_storage_90"),
          maxCameras: 80,
        },
        {
          value: 180,
          label: this.$t("days_of_storage_180"),
          maxCameras: 40,
        },
        {
          value: 365,
          label: this.$t("days_of_storage_365"),
          maxCameras: 20,
        },
        {
          value: 730,
          label: this.$t("days_of_storage_730"),
          maxCameras: 10,
        },
      ],
      selectedSubscription: this.subscription,
      selectedSubscriptionName: null,
      selectedSubscriptionCameras: null,
    };
  },
  created() {
    if (this.subscription) {
      this.cloudStorage = this.subscription.retention !== 0;
      this.daysOfStorageNumber = Math.ceil(this.subscription.retention / 24);
      this.videoQuality = this.subscription.quality;
      this.numberOfCameras = this.subscription.maxAmountOfCameras;
    }
  },
  computed: {
    hasAvailableSubscriptions() {
      return this.availableSubscriptions ? this.availableSubscriptions.length > 0 : null;
    },
    availableSubscriptions() {
      return this.subscriptions
        ? this.subscriptions.filter(function (s) {
            return s.available;
          })
        : null;
    },
    isCustomSubscription() {
      if (this.availableSubscriptions) {
        return (
          this.availableSubscriptions[0] &&
          (this.availableSubscriptions[0].sku === null ||
            this.availableSubscriptions[0].quality === null ||
            this.availableSubscriptions[0].retention === null ||
            this.availableSubscriptions[0].maxAmountOfCameras === null)
        );
      }
      return null;
    },
    getSubscriptionsSKU() {
      let sku = "";
      if (this.selectedSubscription) {
        sku = this.selectedSubscription.sku;
      }
      return sku;
    },
    isBrowserIE() {
      return this.$store.getters.isIE;
    },
    customSubscriptions() {
      let subscriptions = [];
      if (this.availableSubscriptions) {
        const nofSubscriptions = this.availableSubscriptions.length;
        for (let i = 0; i < nofSubscriptions; i++) {
          let name = this.availableSubscriptions[i].name;
          let cams = 0;
          let splittedName = name.split("_");
          if (splittedName.length > 1) {
            cams = parseInt(splittedName.pop());
            name = splittedName.join("_");
          } else {
            cams = parseInt(this.availableSubscriptions[i].maxAmountOfCameras);
          }
          let sub = subscriptions.find(function (s) {
            return s.name === name;
          });
          if (sub) {
            sub.cams.push({ value: cams });
          } else {
            sub = {
              name: name,
              cams: [{ value: cams }],
            };
            subscriptions.push(sub);
          }
        }
        for (let i = 0; i < subscriptions.length; i++) {
          subscriptions[i].cams = subscriptions[i].cams.sort(function (a, b) {
            return a.value - b.value;
          });
        }
      }
      return subscriptions;
    },
    customSubscriptionCameras() {
      return this.selectedSubscriptionName ? this.selectedSubscriptionName.cams : [];
    },
  },
  components: {
    NumberSpinner,
    TwoSidesSwitch,
  },
  methods: {
    onSelectCustomSubscriptionName(name) {
      this.selectedSubscriptionName = name;
      this.selectedSubscriptionCameras = null;
      this.selectedSubscription = null;
    },
    onSelectCustomSubscriptionCameras(cams) {
      this.selectedSubscriptionCameras = cams;
      const name = this.selectedSubscriptionName.name;
      const nofCams = cams.value;
      this.selectedSubscription = this.availableSubscriptions.find(function (s) {
        return s.name.indexOf(name) !== -1 && parseInt(s.maxAmountOfCameras) === nofCams;
      });
    },
    hasAccountSKUProperty(account) {
      return account.hasOwnProperty("SKU") && account.SKU !== "";
    },
    getIdForVideoQualityButton(item) {
      return item.value.toString().toLowerCase() + "-video-quality-button";
    },
    getIdForDaysOfStorageButton(item) {
      return "days-storage-" + item.value.toString().toLowerCase() + "-button";
    },
    selectSubscription() {
      this.selectedSubscription = null;
      const videoQuality = this.videoQuality;
      const maxAmountOfCameras = this.numberOfCameras ? this.numberOfCameras : 0;
      const storageHours = parseInt(this.daysOfStorageNumber) * 24;

      if (videoQuality && maxAmountOfCameras > 0 && storageHours !== null) {
        let subscription = this.availableSubscriptions.find(function (s) {
          return (
            s.quality === videoQuality && s.maxAmountOfCameras === maxAmountOfCameras && s.retention === storageHours
          );
        });
        this.selectedSubscription = subscription;
      }
    },
    getDaysInHours(days) {
      return days * 24;
    },
    onSelectCustomSubscription(subscription) {
      this.selectedSubscription = subscription;
    },
    subscriptionTypeChanged(value) {
      this.cloudStorage = !value;
    },
    cameraNumberChanged(value) {
      this.numberOfCameras = value ? parseInt(value) : 0;
    },
    getMaxCamerasNumber(value) {
      for (let i = 0; this.daysOfStorage; i++) {
        if (this.daysOfStorage[i].value === value) {
          return this.daysOfStorage[i].maxCameras;
        }
      }
      return 0;
    },
    isDayButtonSelected(value) {
      return this.daysOfStorageNumber === value;
    },
    setDaysOfStorage(value) {
      this.daysOfStorageNumber = value;
    },
    isVideoQualityButtonSelected(value) {
      return this.videoQuality === value;
    },
    setVideoQuality(value) {
      this.videoQuality = value;
    },
  },
  watch: {
    subscription: function (newVal) {
      if (newVal) {
        this.cloudStorage = this.subscription.retention !== 0;
        this.daysOfStorageNumber = Math.ceil(this.subscription.retention / 24);
        this.videoQuality = this.subscription.quality;
        this.maxCamerasNumber = this.subscription.maxAmountOfCameras;
      }
    },
    selectedSubscription: function (newVal) {
      this.$emit("subscriptionSelected", newVal);
    },
    daysOfStorageNumber: function (newVal) {
      if (newVal > 0) {
        this.maxCamerasNumber = this.getMaxCamerasNumber(newVal);
        this.selectSubscription();
      }
    },
    videoQuality: function () {
      this.selectSubscription();
    },
    numberOfCameras: function () {
      this.selectSubscription();
    },
    cloudStorage: function (newVal) {
      if (!newVal) {
        this.videoQuality = CM_LIVE_ONLY_GROUP_NAME;
        this.daysOfStorageNumber = CM_LIVE_ONLY_DAYS_STORAGE;
        this.maxCamerasNumber = CM_LIVE_ONLY_MAX_CAMERAS;
      } else {
        this.videoQuality = INITIAL_GROUP_NAME;
        this.daysOfStorageNumber = INITIAL_DAYS_STORAGE;
        this.maxCamerasNumber = INITIAL_MAX_CAMERAS;
      }
      this.selectedSubscription = null;
      this.selectSubscription();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

.choose-subscription-block {
  display: flex;
  justify-content: center;
  padding: 20px 40px;
  min-height: 320px;
  .body {
    width: 100%;
  }
  .item-block {
    padding-top: 10px;
    text-align: center;
  }
}
.choose-subscription-block ::v-deep .switch input[type="checkbox"] + .check {
  border-radius: 4px;
  background-color: #cccccc;
}
.choose-subscription-block ::v-deep .switch input[type="checkbox"] + .check:before {
  border-radius: 4px;
}
.choose-subscription-block ::v-deep .switch .control-label {
  padding-left: 0;
  padding-right: 0.5em;
}
.choose-subscription-block ::v-deep .switch .control-label.is-large {
  font-size: large;
  font-weight: bold;
}
.choose-subscription-block ::v-deep .switch:focus input[type="checkbox"]:checked + .check,
.choose-subscription-block ::v-deep .switch:focus input[type="checkbox"] + .check {
  box-shadow: none;
}

.sku-block {
  p.content {
    margin-bottom: 45px;
  }
}

.video-quality-block .buttons {
  justify-content: center;
}

p.title {
  color: #b9b9b9 !important;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 85% !important;
}

.subscriptions-type-selection {
  justify-content: center;
}

button {
  margin-right: 10px;
  margin-bottom: 10px;
  color: $primary;
  background-color: white;
}

.days-of-storage-block .buttons {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.selected {
  color: white;
  background-color: $primary !important;
}

.columns {
  justify-content: center;
}

.custom-subscriptions {
  width: 50%;
}

#change-number-of-cameras-button.field {
  max-height: 48px;
  margin: 0 auto 0 auto;
  width: 180px;
}

#change-number-of-cameras-button ::v-deep .field {
  max-height: 48px;
  margin: 10px 0;
}
</style>
