/*eslint-disable*/

const XRegExp = require("xregexp");

export function minLength(val, n) {
  return val !== null && (val.length >= n || val.length === 0);
}

export function maxLength(val, n) {
  return val !== null && val.length <= n;
}

export function phoneNumber(val) {
  if (typeof val === "undefined" || val === null || val === "") {
    return true;
  }
  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(val);
}

export function name(val) {
  if (typeof val === "undefined" || val === null || val === "") {
    return true;
  }
  const expr = XRegExp.build("^[\\p{L}0-9_\\- .&+:,;/]*$");
  return expr.test(val);
}

export function password(val) {
  if (typeof val === "undefined" || val === null || val === "") {
    return true;
  }
  return /^[\w-.,!?@$&*^=%:/\\~+#"]*$/.test(val);
}

export function email(val) {
  if (typeof val === "undefined" || val === null || val === "") {
    return true;
  }
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    val
  );
}
