<template>
  <v-container my-8 fluid>
    <v-layout row wrap>
      <v-flex xs8 offset-xs2 mb-4>
        <h2 class="text-center">
          SERVICES SUBSCRIPTION AGREEMENT
        </h2>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">TERMS AND CONDITIONS</p>
        <ol>
          <p>
            These are the terms and conditions on which Ademco Inc., doing business as ADI Global Distribution
            ("Ademco") and Eagle Eye Networks, Inc. and its subsidiaries and affiliates ("Supplier") enable you, the
            Subscriber ("you" or "Subscriber"), to access our cloud-based integrated technology platform currently
            branded as "Capture Cloud Service", as outlined in further detail in this Agreement.
          </p>
          <p>
            These terms and conditions, including any extension, annex and update, together with the Capture Privacy
            Policy, govern the way you will use our platform and all related services including the Software, the
            Services, the Subscription, the Website, the Documentation and the Hardware (as such terms are defined
            below)
          </p>
          <p>
            You acknowledge and agree that the Services are provided directly to you by Supplier and that Ademco solely
            acts as a reseller of Supplier's services. You further acknowledge and agree that Ademco does not have
            physical or logical access to any Subscriber personal data processed via the services nor any responsibility
            for or control over the processing of Subscriber personal data via the Services. All queries, matters and/or
            disputes arising out of or connected to the processing of Subscriber personal data via the Services shall be
            resolved directly and exclusively between Subscriber and Supplier.
          </p>
          <p>
            Please read these terms carefully before you download any Software or use any Services. These terms explain
            how the Services will be provided to you, what to do if there is a problem and other important information.
          </p>
          <p>
            Please refer to the Capture Privacy Policy for information on how information is collected, used and
            disclosed from our users. You acknowledge and agree that your use of Services is subject to the Capture
            Privacy Policy.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">ACCEPTANCE</p>
        <ol type="a">
          <p>
            You are deemed to have accepted the terms of this Agreement and will have created a binding contract with
            Ademco and Supplier upon any of the following events: (a) download or installation of any Services or
            related Software or Hardware; (b) access to or use of any Services or related Software or Hardware; (c)
            payment for Services or related Software, Hardware or Subscription; (d) entering into an order form for
            Services or related Software, Hardware or Subscription; or (e) clicking an "accept" button or other similar
            acknowledgment indicating your acceptance of this Agreement. If you do not agree to be bound by this
            Agreement, you may not access or use the Services or any related Software or Hardware. Please read through
            the entirety of this Agreement to review important provisions regarding intellectual property, disclaimers,
            limitations of liability, indemnification, waivers, exclusive law and jurisdiction for resolution of
            disputes and other legal restrictions.
          </p>
          <p>
            If you are accessing and using the Services on behalf of a company (such as your employer) or other legal
            entity, you represent and warrant that you have the authority to bind that company or other legal entity and
            each of its affiliates to these Terms. In that case, "you" and "your" will refer tothat company or other
            legal entity and its affiliates.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">
          INFORMATION ABOUT US AND CONTACT DETAILS
        </p>
        <ol type="a">
          <p>
            The subscription services provided to you under this Agreement including the Website, cloud services and
            other services and functionality currently branded as "Capture Cloud Service " ("<b>Services</b> ") are
            provided by Ademco Inc., doing business as ADI Global Distribution, a company incorporated in Delaware. Our
            principal office is located at 275 Broadhollow Road Suite 400 Melville, New York 11747 USA. You can contact
            us by emailing our customer service team at
            <a href="mailto:support@capturecloudvms.com.">support@capturecloudvms.com.</a> If we have to contact you, we
            will do so by telephone or by writing to you at the email address you provide to us in your order.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">
          OUR AGREEMENT WITH YOU
        </p>
        <ol type="a">
          <p>
            You may use the Services only if you are 18 years or older and capable of forming a bindingcontract with
            Ademco and Supplier and are not barred from using the Services under applicablelaw. You may only use the
            Services if you are located in the United States of America or Canadaand then only to provide your own
            services to your own customers located in the United States ofAmerica or Canada.It is a requirement of use
            of the Services, Software or Hardware that you accept to be bound by this Agreement.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">1 DEFINITIONS</p>
        <ol type="a">
          <p>
            1.1 Capitalized words in this Contract have the following meaning:
          </p>
          <p>
            <b> "Website" </b>means the website which Ademco provides to make use of the Services, located at
            <a href="www.captureadiglobal.com">www.captureadiglobal.com</a> or such additional or replacement website as
            may be notified to Subscribers by Ademco from time to time.
          </p>
          <p>
            <b> "Agreement" </b>means these terms and conditions, the online order form and any other documents
            incorporated by reference, such as the Capture Privacy Policy.
          </p>
          <p>
            <b> "Business Day" </b>means a day other than a Saturday, Sunday or public holiday when banks are open for
            business.
          </p>
          <p>
            <b> "Documentation" </b>means the official published service description and other documentation made
            available to Subscribers by Ademco via the Website, which sets out a description of the Services and the
            user instructions for the Services.
          </p>
          <p>
            <b> "End User" </b>means any third party (whether a natural person or a legal entity) whose device(s) and/or
            network(s) is monitored by Subscribers via the Software, Hardware and Services.
          </p>
          <p><b> "Hardware" </b>means hardware which may be purchased from Ademco in order to run the Software.</p>
          <p>
            <b> "Capture Privacy Policy" </b>means the Ademco privacy policy for the Hardware, Software, Services,
            Subscription and Website, which describes how Subscriber information is collected, used, shared and stored
            in the course of providing the Services, available at
            <a href="www.capture.adiglobal.com">www.capture.adiglobal.com</a> as may be amended and updated from time to
            time. Ademco's other products, services and websites may be subject to Ademco's other privacy policies, as
            indicated on/in the context of the relevant product, service or website.
          </p>
          <p><b> "Services" </b>means the cloud-based integrated technology storage platform.</p>
          <p>
            <b> "Subscriber" </b>means all software used by Ademco and/or made available to Subscribers in connection
            with the provision of the Services.
          </p>
          <p>
            <b> "Subscription" </b>means an active subscription sold by Ademco to allow access and use of the Services
            in accordance with this Agreement.
          </p>
          <p>
            <b> "Support Services Policy" </b>means Ademco's policy for providing support in relation to the Services as
            made available at <a href="mailto:support@capturecloudvms.com">support@capturecloudvms.com</a> or such other
            website address as may be notified to Subscribers from time to time.
          </p>
          <p>
            <b> "User Data" </b>means the data and information input into or collected by the Software or generated in
            the course of using the Services that relates to Subscriber;
          </p>
          <p>
            <b> "Virus" </b>means any software, code, file or program which may prevent, impair or otherwise adversely
            affect the operation of any computer software, hardware or network, any telecommunications service,
            equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to
            or the operation of any program or data, including the reliability of any program or data (whether by
            re-arranging, altering or erasing the program or data in whole or part or otherwise); or adversely affect
            the user experience, including worms, trojan horses, viruses and other similar things or devices.
          </p>
          <p>
            1.2 A person includes an individual, corporate or unincorporated body (whether or not having separate legal
            personality).
          </p>
          <p>
            1.3 A reference to a company shall include any company, corporation or other body corporate, wherever and
            however incorporated or established.
          </p>
          <p>
            1.4 Unless the context otherwise requires, words in the singular shall include the plural and in the plural
            shall include the singular.
          </p>
          <p>
            1.5 Unless the context otherwise requires, a reference to one gender shall include a reference to the other
            genders.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">2 SERVICES</p>
        <ol type="a">
          <p>
            2.1 The Services are described in further detail in the Documentation (and as may be subsequently revised or
            updated by Ademco).
          </p>
          <p>
            2.2 Subscribers are permitted to use the Services provided that the Subscriber has purchased the required
            Subscriptions.
          </p>
          <p>
            2.3 With respect to the Services:
          </p>
          <p>
            2.3.1 Subscriber may be required to create an account to access the Services (the "Account"). Subscriber
            shall provide Ademco with accurate, complete and up-to-date information with regard to Subscriber's Account,
            and should update such information as needed. Ademco may suspend or terminate your access to the Services
            and/or this Agreement if Ademco does not have up-to-date Account information.
          </p>
          <p>
            2.3.2 Subscriber shall keep a secure password for its use of the Services and shall keep such password
            confidential. Subscriber agrees not to disclose its Account password to anyone and to notify Ademco
            immediately of any unauthorized use of its Account.
          </p>
          <p>
            2.3.3 Rights granted to Subscriber are non-exclusive. Subscriber obtains no preferential rights to specific
            territories, markets, customers, pricing or otherwise. Ademco reserves the right to engage additional
            Subscribers.
          </p>
          <p>
            2.4 Subscriber shall not access, store, distribute or transmit any Viruses, or any material, or otherwise
            use the Services in any manner that:
          </p>
          <p>
            2.4.1 is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or
            ethnically offensive;
          </p>
          <p>
            2.4.2 facilitates illegal activity;
          </p>
          <p>
            2.4.3 depicts sexually explicit images;
          </p>
          <p>
            2.4.4 promotes unlawful violence;
          </p>
          <p>
            2.4.5 is discriminatory based on race, gender, color, religious belief, sexual orientation, disability; or
          </p>
          <p>
            2.4.6 is otherwise illegal or causes damage or injury to any person or property;
          </p>
          <p>
            2.5 Subscriber shall not:
          </p>
          <p>
            2.5.1 except to the extent expressly permitted under this Agreement or as may otherwise be allowed by any
            applicable law which is incapable of exclusion by Agreement between the parties:
          </p>
          <p>
            (a) attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download,
            display or transmit all or any portion of the Software or Hardware in any form or media or by any means; or
          </p>
          <p>
            (b) attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form
            all or any part of the Software; or
          </p>
          <p>
            2.5.2 access all or any part of the Services and Documentation in order to build a product or service which
            competes with the Services;
          </p>
          <p>
            2.5.3 license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially
            exploit, or otherwise make the Services and/or Documentation available to any third party except bona fide
            End Users of a Subscriber;
          </p>
          <p>
            2.5.4 attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation,
            other than as provided under this clause 2;
          </p>
          <p>
            2.5.5 attempt to obtain, access or derive in any fashion the underlying source code of the Software used in
            the Services, recognizing that this Agreement does not convey any rights to source code and that all such
            rights in and to the source code of the Software are reserved to Ademco and its licensors;
          </p>
          <p>
            2.5.6 use the Services to monitor network(s) and/or devices of children under the age of 16 or otherwise
            direct, or knowingly collect information from, children under the age of 16 via the Services.
          </p>
          <p>
            2.5.2 access all or any part of the Services and Documentation in order to build a product or service which
            competes with the Services;
          </p>
          <p>
            2.6 Subscribers shall use all reasonable efforts to prevent any unauthorized access to, or use of, the
            Services and/or the Documentation and, in the event of any such unauthorized access or use, promptly notify
            Ademco. Subscriber is liable for all unauthorized access to or use of the Services and/or the Documentation
            that occurs through their Account.
          </p>
          <p>
            2.7 Ademco may offer additional products, services or enhanced functionality for Subscribers to purchase in
            accordance with the terms of this Agreement ("<b>Additional Services</b>"). At the Subscriber's option, the
            parties may agree in writing for the provision of Additional Services during the term of this Agreement by
            completing a specified order form.
          </p>
          <p>
            2.8 The fee for providing Additional Services under this Section will be agreed by the parties prior to the
            performance of such Additional Services.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">3 MAINTENANCE</p>
        <ol type="a">
          <p>
            3.1 Ademco shall provide the Services to Subscriber on and subject to the terms of this Agreement.
          </p>
          <p>
            3.2 Ademco generally intends to use commercially reasonable efforts to make the Services available 24 hours
            a day, seven days a week, except for:
          </p>
          <p>
            3.2.1 planned maintenance carried out during the maintenance window (to be reasonably advised via the
            Website or Software from time to time); and
          </p>
          <p>
            3.2.2 temporary suspension without notice, for security reasons, systems failure, emergency maintenance and
            repair, events of force majeure or other circumstances that warrant suspension in the reasonable
            determination of Ademco.
          </p>
          <p>
            Subscriber will not be entitled to any refund or rebate for such suspensions, and Ademco does not guarantee
            any specific level or availability or that the Services will be free from interruptions.
          </p>
          <p>
            3.3 Ademco will, as part of the Services, provide Subscriber with Ademco's standard customer support
            services during Normal Business Hours in accordance with Ademco's Support Services Policy in effect at the
            time that the Services are provided. Ademco may from time to time amend the Support Services Policy in its
            sole and absolute discretion.
          </p>
          <p>
            3.4 Ademco may from time to time develop patches, bug fixes, updates, upgrades, and other modifications to
            improve the Software and Services ("<b>Updates</b>"). These may be automatically installed on some versions
            of the Hardware without any additional notice or consent from Subscriber or End Users (and Subscriber must
            inform End Users of this and ensure they have agreed to it). For other versions of the Hardware you may be
            required to install and download Updates. If you do not want such Updates, your remedy is to terminate your
            Account and stop using the Services immediately. Your continued use of the Services is your agreement to
            receive Updates from Ademco.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">4 USER DATA</p>
        <ol type="a">
          <p>
            4.1 Subscriber acknowledges that the Services, Software and Hardware collect, manage, record and store data
            obtained from on premise video surveillance at relevant End User's location, as further detailed in the
            Documentation, in order for Subscriber to provide its own services to such End User. Subscriber further
            acknowledges that the collection and processing of such data requires each End User's informed prior consent
            and, in using the Services, Software and Hardware, Subscriber represents and warrants on an ongoing basis
            that it has informed the relevant End User(s) appropriately and obtained such consent. Under no
            circumstances shall Subscriber use the Services, Software and Hardware without the informed prior consent of
            all relevant End Users. In the event any End User withdraws their consent, Subscriber must cease providing
            video monitoring services via the Hardware, Software and the Services
          </p>
          <p>
            4.2 Ademco does not know the identity of nor have a direct contractual relationship with the End Users.
            Subscriber also acknowledges that the Software, Hardware and Services rely in part on software, hardware and
            services provided by Ademco's partners. Ademco has limited logical access to End User Data. However,
            Subscriber acknowledges and agrees that Ademco does not have physical or logical access to End User Data and
            only facilitates the processing of such End User Data via the Services, Software and Hardware. Ademco
            processes End User Data as described in the Capture Privacy Policy (which includes processing such End User
            Data for its own purposes). In respect to End User Data, Ademco acts on Subscriber's behalf as Subscriber's
            service provider and Subscriber specifically instructs Ademco to process End User Data as contemplated in
            this Agreement (and as further detailed in the Documentation). Subscriber is solely responsible for the
            lawfulness of such End User Data processing (including, for the avoidance of doubt, for obtaining informed
            prior consent of reach relevant End User). In the event Subscriber wishes to obtain a copy of or carry out
            any action in respect of any End User Data (other than as made available to Subscriber via the standard
            functionality of the Software, Services and Hardware), or if any third party (including any End User) has
            any question or concern about relevant End User Data or wishes to exercise any of their legal rights in
            respect of such End User Data, Subscriber understands that Ademco may refer Subscriber to Supplier with
            physical and/or logical access to such End User Data and Subscriber agrees that, in such case, it will
            interact directly with such partner. Subscriber will provide commercially reasonable assistance to
            Subscriber in this context where reasonably required.
          </p>
          <p>
            4.3 Subscriber shall have sole responsibility for the legality, reliability, integrity, accuracy and quality
            of any End User data.
          </p>
          <p>
            4.4 By entering into this Agreement, Subscriber is to have read and accepted the Capture Privacy Policy,
            which is expressly incorporated herein by reference and contain important information about End User Data
            and Network Data, including but not limited to:
          </p>
          <p>
            4.4.1 the type of information collected;
          </p>
          <p>
            4.4.2 how such information is used;
          </p>
          <p>
            4.4.3 the circumstances in which Subscriber information is shared with third parties;
          </p>
          <p>
            4.4.4 how Ademco products and services interact with third parties; and
          </p>
          <p>
            4.4.5 how Subscriber information is stored and protected.
          </p>
          <p>
            4.5 The End User Data is subject to reasonable security and backup procedures but Ademco and Supplier do not
            guarantee the security and/or continued availability of End User Data. In the event of any loss or damage to
            End User Data the Subscriber's sole and exclusive remedy shall be for Ademco or Supplier to use reasonable
            commercial efforts to restore the lost or damaged End User Data from the latest back-up available to Ademco
            or Supplier. Ademco and Supplier shall not be responsible for any loss, destruction, alteration or
            disclosure of End User Data caused by any third party.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">5 HARDWARE AND SOFTWARE</p>
        <ol type="a">
          <p>
            5.1 In order to make use of the Services, Subscriber will require certain hardware and software as set out
            in this clause.
          </p>
          <p>
            5.2 In order for the Services to work, the Hardware or Software must be installed on the End User's video
            surveillance network.
          </p>
          <p>
            5.3 Ademco grants to Subscriber a non-transferable, non-exclusive, temporary license to use and operate the
            Software in accordance with and subject to the restrictions of this Agreement.
          </p>
          <p>
            5.4 Subscriber shall purchase Hardware under separate purchase orders, subject to the terms and conditions
            available at: (INSERT TS AND CS).
          </p>
          <p>
            5.5 The use of the Software may be subject to additional terms and conditions imposed by third parties
            and/or opensource license terms which may be notified at the time of download (and which take precedence
            over this Agreement in relation to their use). Subscriber agree to comply with any such terms and
            acknowledge that use of Software does not limit or curtail Subscriber's rights under the applicable open
            source license terms. A list of the opensource licenses relevant to users of the Services is available on
            the Website.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">6 ADEMCO'S OBLIGATIONS</p>
        <ol type="a">
          <p>
            6.1 Ademco undertakes that the Services will be performed using commercially reasonable efforts
            substantially in accordance with the Documentation.
          </p>
          <p>
            6.2 The undertaking at clause 6.1 shall not apply to the extent of any non-conformance which is caused by
            use of the Services contrary to Ademco's instructions, or modification or alteration of the Services by any
            party other than Ademco or Ademco's duly authorized contractors or agents. Notwithstanding the above,
            Ademco:
          </p>
          <p>
            6.2.1 does not warrant that Subscriber's use of the Services or Software will be uninterrupted or
            error-free, or that the Services, Software, Documentation and/or the information obtained by Subscriber
            through the Services or Software will meet Subscriber's requirements; and
          </p>
          <p>
            6.2.2 is not responsible for any delays, delivery failures, or any other loss or damage resulting from the
            transfer of data over communications networks and facilities, including the internet, or any other elements
            provided by or controlled by third parties and Subscribers acknowledge that the Services and Documentation
            may be subject to limitations, delays and other problems inherent in the use of such communications
            facilities or other third-party elements.
          </p>
          <p>
            6.3 This Agreement shall not prevent Ademco from entering into similar agreements with third parties, or
            from independently developing, using, selling or licensing documentation, products and/or services which are
            similar to those provided under this Agreement.
          </p>
          <p>
            6.4 Without prejudice to Section 4, Ademco will use commercially reasonable efforts to maintain all
            necessary licenses, consents, and permissions necessary for the performance of its obligations under this
            Agreement.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">7 SUBSCRIBER OBLIGATIONS</p>
        <ol type="a">
          <p>
            7.1 Subscriber shall:
          </p>
          <p>
            7.1.1 provide Ademco with all necessary co-operation in relation to this Agreement and all necessary access
            to such information as may be required by Ademco in order to provide the Services, including but not limited
            to User Data, Network Data, security access and configuration information;
          </p>
          <p>
            7.1.2 comply with all applicable laws and regulations with respect to its activities under this Agreement
            (including the requirement to appropriately inform and obtain the consent of each End User, as described in
            Section 4);
          </p>
          <p>
            7.1.3 ensure that they use the Services, Software, Hardware and Documentation in accordance with the terms
            and conditions of this Agreement and remain responsible for any breach of this Agreement;
          </p>
          <p>
            7.1.4 ensure that its network and systems comply with the relevant specifications provided by Ademco from
            time to time where required for use of the Services; and
          </p>
          <p>
            7.1.5 be solely responsible for procuring and maintaining its network connections and telecommunications
            links, and all problems, conditions, delays, delivery failures and all other loss or damage arising from or
            relating to Subscribers' network connections or telecommunications links or caused by the internet.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">8 CHARGES AND PAYMENT</p>
        <ol type="a">
          <p>
            8.1 To access the Services, Subscriber must purchase Subscriptions via the Website. All relevant information
            concerning price, duration, activation and other matters relating to use of the Subscriptions is provided on
            the Website and may change from time to time. Once your purchase is complete, you will receive an email from
            us confirming your purchase.
          </p>
          <p>
            8.2 Subscriptions shall be paid via credit card which you shall provide to Ademco. When you provide your
            credit card details to Ademco, you authorize Ademco to bill such credit card on the effective date for the
            Subscription Fees ("Effective Date") payable in respect of the Initial Subscription Term and subject to this
            Agreement, on each monthly anniversary of the Effective Date for the Subscription Fees payable in respect to
            the next renewal Term. Your credit card details and all other personal information will be handled in
            accordance with the Privacy Policy. You agree to provide and maintain valid up-to date credit card
            information and any other relevant valid, up-to-date and complete contact and billing details. If additional
            Subscriptions are purchased, Ademco shall add those Subscriptions to the credit card provided to Ademco and
            Ademco will not accept additional credit cards for multiple Subscriptions unless Ademco consents in writing.
          </p>
          <p>
            8.3 If Ademco has not received payment within 30 days after the due date, and without prejudice to any other
            rights and remedies of Ademco, Ademco may without liability to you, disable your password, Account and
            access to all or part of the Services and Ademco shall be under no obligation to provide any or all of the
            Services while payment remains unpaid; and Ademco may charge late fees in accordance with applicable law.
          </p>
          <p>
            8.4 All amounts and fees stated or referred to in this Agreement:
          </p>
          <p>
            8.4.1 shall be payable in the applicable currency specified on the online order form;
          </p>
          <p>
            8.4.2 are non-cancellable and non-refundable;
          </p>
          <p>
            8.4.3 are exclusive of value added tax or other sales or use taxes, which Subscribers agree to pay at the
            appropriate rate.
          </p>
          <p>
            8.5 Ademco shall be entitled to increase the Subscription Fees for licenses, and other charges at the start
            of each Renewal Period upon 30 days notice to the Subscriber. Such price changes will not affect existing
            licenses already purchased.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">9. PROPRIETARY RIGHTS</p>
        <ol type="a">
          <p>
            9.1 You acknowledge and agree that Ademco and/or its licensors own all intellectual property rights in the
            Software, the Services, Hardware and the Documentation. Except as expressly stated herein, this Agreement
            does not grant Subscribers any rights to, or in, patents, copyright, database right, trade secrets, trade
            names, trademarks (whether registered or unregistered), or any other rights or licenses in respect of the
            Software, the Services, Hardware or the Documentation.
          </p>
          <p>
            9.2 Ademco reserves all other rights in its brand, trademarks and other intellectual property. Subscribers
            acknowledge and agree that they will not, at any time, whether during or after termination of this
            Agreement.
          </p>
          <p>
            9.2.1 adopt or use any trademarks, symbol or device which incorporates or is confusingly similar to the
            Ademco trademarks;
          </p>
          <p>
            9.2.2 apply anywhere in the world to register any trademarks identical to or resembling the Ademco
            trademarks so as to be likely to deceive or cause confusion;
          </p>
          <p>
            9.2.3 use the Ademco trademarks as part of any corporate, business or trading name or style of the
            Subscriber; or
          </p>
          <p>
            9.2.4 register any domain name anywhere in the world which incorporates or is similar to any Ademco
            trademarks.
          </p>
          <p>
            9.3 Subscriber acknowledges that Ademco trademarks are the property of Ademco and that Subscribers do not
            have any right, title or interest in or to Ademco trademarks and that any goodwill arising out of
            Subscriber's use of Ademco trademarks shall accrue to the benefit of Ademco. Insofar as any goodwill or
            other rights may have accrued to a Subscriber by reason of the use of Ademco trademarks by a Subscriber
            and/or by the Subscriber being connected with Ademco trademarks in the course of trade, a Subscriber shall
            hold all such goodwill and other rights on trust for Ademco and shall, if requested by Ademco, execute such
            assignments of such goodwill and other rights to Ademco (or its nominee) as Ademco may direct.
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">10 INDEMNITY</p>
        <ol type="a">
          <p>
            10.1 Subscriber shall defend, indemnify and hold harmless Ademco, its affiliates and their respective
            officers, directors, employees, contractors, agents and customers against any claims, actions, proceedings,
            losses, damages, expenses and costs (including, without limitation, court costs and reasonable legal fees,
            and any claims) arising out of or in connection with Subscriber's use of the Services, Software, Hardware or
            Documentation or collection or processing of Network Data via them (other than those which arise from a
            breach of this Agreement by Ademco), provided that:
          </p>
          <p>
            10.1.1 Subscriber is given prompt notice of any such claim;
          </p>
          <p>
            10.1.2 Ademco provides reasonable co-operation to Subscriber in the defense and settlement of such claim, at
            the Subscriber's expense; and
          </p>
          <p>
            10.1.3 Subscriber is given sole authority to defend or settle the claim.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">11 LIMITATION OF LIABILITY; DISCLAIMER</p>
        <ol type="a">
          <p>
            11.1 This clause sets out the entire liability of Ademco (including any liability for the acts or omissions
            of its employees, agents and sub-contractors) to Subscribers.
          </p>
          <p>
            11.1.1 IN NO EVENT SHALL ADEMCO, ITS AFFILIATES, CONTRACTORS AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
            SHAREHOLDERS, EMPLOYEES, AGENTS AND REPRESENTATIVES (COLLECTIVELY, "ADEMCO PARTIES") BE LIABLE FOR ANY
            INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR
            ANTICIPATED PROFITS, ECONOMIC LOSSES, LOSS OF OR DAMAGE TO DATA, LOSS OF GOODWILL AND DAMAGE ARISING OUT OF
            UNLAWFUL COLLECTION OR PROCESSING OF NETWORK DATA, EVEN IF ANY OF THE ADEMCO PARTIES HAVE BEEN INFORMED OF
            THE POSSIBILITY OF SUCH DAMAGES. THIS PARAGRAPH SHALL SURVIVE TERMINATION OF THIS AGREEMENT FOR ANY REASON
            AND SHALL REMAIN IN FULL FORCE AND EFFECT DESPITE ANY FAILURE OF ANY REMEDY HEREIN.
          </p>
          <p>
            11.1.2 IN NO EVENT SHALL THE ADEMCO PARTIES' LIABILITY, IN THE AGGREGATE FOR ANY DAMAGES TO SUBSCRIBER OR
            ANY OTHER PARTY EXCEED THE SUBSCRIPTION FEES PAID TO ADEMCO BY SUBSCRIBER FOR THE SERVICES DURING THE TWELVE
            (12) MONTH PERIOD PRECEDING THE EVENTS GIVING RISE TO THE CLAIM, REGARDLESS OF THE FORM OF ACTION, WHETHER
            IN CONTRACT, NEGLIGENCE, STRICT LIABILITY, TORT, PRODUCTS LIABILITY, EQUITY OR OTHERWISE.
          </p>
          <p>
            11.1.3 IN THE EVENT THAT SUBSCRIBER SEEKS TO ASSERT A CLAIM AGAINST ANY OF THE ADEMCO PARTIES ARISING FROM
            OR IN CONNECTION WITH THIS AGREEMENT OR THE SERVICES, SUBSCRIBER SHALL BRING THE CLAIM WITHIN ONE (1) YEAR
            OF THE OCCURRENCE OF THE EVENTS GIVING RISE TO THE CLAIM OTHERWISE THE CLAIM SHALL BE DEEMED WAIVED AND
            BARRED FOREVER.
          </p>
          <p>
            11.2 Except as expressly and specifically provided in this Agreement:
          </p>
          <p>
            11.2.1 all warranties, representations, conditions and all other terms of any kind whatsoever implied by
            statute or common law are, to the fullest extent permitted by applicable law, excluded from this Agreement;
            and
          </p>
          <p>
            11.2.2 the Services and the Documentation are provided to you on an "as is" basis.
          </p>
          <p>
            11.2.3 EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, ADEMCO MAKES NO REPRESENTATIONS OR WARRANTIES,
            WHETHER WRITTEN OR ORAL, EXPRESS OR IMPLIED, (INCLUDING, WITHOUT LIMITATION, REPRESENTATIONS OR WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE, INTEGRATION, SECURITY, NON-INFRINGEMENT OR TITLE) WITH
            RESPECT TO THE SOFTWARE, DOCUMENTATION, OR SERVICES PROVIDED HEREUNDER, OR ANY MODIFICATION, REVISIONS, OR
            DERIVATIVE WORKS OF THE SOFTWARE, DOCUMENTATION OR SERVICES. ADEMCO DOES NOT WARRANT THAT THE SOFTWARE,
            DOCUMENTATION OR SERVICES WILL BE ERROR-FREE.
          </p>
          <p>
            11.3 Nothing in this Agreement excludes the liability of Ademco that is prohibited by applicable law from
            being disclaimed.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">12 TERMINATION</p>
        <ol type="a">
          <p>
            We may terminate your access to and use of the Services, at our sole discretion, at any time and without
            notice to you. You may cancel your Account at any time from your profile area of the Website, or by emailing
            us at <a href="mailto:captureremotesupport@adiglobal.com">captureremotesupport@adiglobal.com</a>. Upon any
            termination, discontinuation or cancellation of Services or your Account, all provisions of this Agreement
            which by their nature should survive will survive, including, without limitation, ownership provisions,
            warranty disclaimers, limitations of liability, and dispute resolution provisions.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">13 FORCE MAJEURE</p>
        <ol type="a">
          <p>
            Ademco shall have no liability to Subscriber under this Agreement if it is prevented from or delayed in
            performing its obligations under this Agreement, or from carrying on its business, by acts, events,
            omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or
            other industrial disputes (whether involving the workforce of Ademco or any other party), failure of a
            utility service or transport or telecommunications network, act of God, war, riot, civil commotion,
            pandemics, malicious damage, compliance with any law or governmental order, rule, regulation or direction,
            accident, breakdown of plant or machinery, fire, flood, storm, acts of third parties, provided that
            Subscriber are notified of such an event and its expected duration.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">14 CONFLICT</p>
        <ol type="a">
          <p>
            If there is an inconsistency between any of the provisions in the main body of this Agreement and the
            Documentation, the provisions in the main body of this Agreement shall prevail.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">15 CHANGES TO THESE TERMS</p>
        <ol type="a">
          <p>
            We may modify this Agreement at any time, in our sole discretion. If we do so, we'll let you know either by
            posting the modified Agreement on the Website or through other communications. It's important that you
            review the Agreement whenever we modify it because if you continue to use the Services after we have posted
            modified Agreement on the Website, you are indicating to us that you agree to be bound by the modified
            Agreement. If you don't agree to be bound by the modified Agreement, then you may not use the Services
            anymore. Because our Services are evolving over time we may change or discontinue all or any part of the
            Services, at any time and without notice, at our sole discretion.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">16 VARIATION</p>
        <ol type="a">
          <p>
            Without prejudice to Section 15, no variation of this Agreement shall be effective unless it is in writing
            and signed by the parties (or their authorized representatives).
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">17 WAIVER</p>
        <ol type="a">
          <p>
            No failure or delay by a party to exercise any right or remedy provided under this Agreement or by law shall
            constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further
            exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall
            prevent or restrict the further exercise of that or any other right or remedy
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">18 RIGHTS AND REMEDIES</p>
        <ol type="a">
          <p>
            Except as expressly provided in this Agreement, the rights and remedies provided under this Agreement are in
            addition to, and not exclusive of, any rights or remedies provided by law.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">19 SEVERANCE</p>
        <ol type="a">
          <p>
            19.1 If any provision (or part of a provision) of this Agreement is found by any court or administrative
            body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in
            force. <br />
            19.2 If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of
            it were deleted, the provision shall apply with whatever modification is necessary to give effect to the
            commercial intention of the parties.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">20 ENTIRE AGREEMENT</p>
        <ol type="a">
          <p>
            20.1 This Agreement, and any documents referred to in it, constitute the whole Agreement between the parties
            and supersede any previous arrangement, understanding or Agreement between them relating to the subject
            matter they cover.<br />
            20.2 The parties acknowledge and agree that in entering into this Agreement, neither rely on any
            undertaking, promise, assurance, statement, representation, warranty or understanding (whether in writing or
            not) of any person (whether party to this Agreement or not) relating to the subject matter of this
            Agreement, other than as expressly set out in this Agreement
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">21 ASSIGNMENT</p>
        <ol type="a">
          <p>
            21.1 Subscriber shall not, without the prior written consent of Ademco, whether by operation of law or
            otherwise assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights
            or obligations under this Agreement.<br />
            21.2 Ademco may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or
            any of its rights or obligations under this Agreement
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">22 NO PARTNERSHIP OR AGENCY</p>
        <ol type="a">
          <p>
            Nothing in this Agreement is intended to or shall operate to create a partnership between the parties, or
            authorize either party to act as agent for the other, and neither party shall have the authority to act in
            the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the
            making of any representation or warranty, the assumption of any obligation or liability and the exercise of
            any right or power). Furthermore, nothing in this Agreement creates or establishes a dealership or franchise
            relationship between the parties. Subscriber expressly waives any dealership or franchise rights and agrees
            not to assert dealership or franchise relationship exists
          </p>
        </ol>
      </v-flex>

      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">23 THIRD PARTY RIGHTS</p>
        <ol type="a">
          <p>
            This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or
            formation (including non-contractual disputes or claims) shall be governed by and construed in accordance
            with the laws of the State of New York, USA. The parties expressly disclaim application of the United
            Nations Convention on the International Sale of Goods
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">24 NOTICES</p>
        <ol type="a">
          <p>
            24.1 Any notice required to be given under this Agreement shall be in writing and shall be delivered by hand
            or sent by pre-paid first-class post or recorded delivery post to the other party at its address set out in
            this Agreement, or such other address as may have been notified by that party for such purposes, or sent by
            fax or e-mail to the other party's fax number or e-mail address as set out in this Agreement. Notices may
            also be given as described in Section 27. <br />
            24.2 A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is not
            in business hours, at 9.00 am on the first Business Day following delivery). A correctly addressed notice
            sent by pre-paid first-class post or recorded delivery post shall be deemed to have been received at the
            time at which it would have been delivered in the normal course of post. A notice sent by fax shall be
            deemed to have been received at the time of transmission (as shown by the timed printout obtained by the
            sender). An e-mail will be deemed to have been received the following Business Day.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">25 GOVERNING LAW</p>
        <ol type="a">
          <p>
            This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or
            formation (including non-contractual disputes or claims) shall be governed by and construed in accordance
            with the laws of the State of New York, USA. The parties expressly disclaim application of the United
            Nations Convention on the International Sale of Goods
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">26 EXCLUSIVE JURISDICTION; COSTS</p>
        <ol type="a">
          <p>
            Each party irrevocably agrees that the state or federal courts located in the State of New York, USA shall
            have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this
            Agreement or its subject matter or formation (including non-contractual disputes or claims). Each party
            waives all defenses to this exclusive jurisdiction including, without limitation, defenses of forum
            nonconveniens. In connection with any judicial proceedings relating to or arising from any disputes under
            this Agreement, Ademco shall be entitled to recovery of its reasonable costs and expenses (including without
            limitation attorneys' fees and costs) incurred with respect to those claims upon which it prevails. Prior to
            pursuing judicial action, Subscriber agrees to notify Ademco of the claim in writing with sufficient detail
            and attempt to resolve the claim informally through good faith discussions for a period of sixty (60) days
            in an effort to avoid judicial action. Each party waives the right to a trial by jury.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">27 ELECTRONIC COMMUNICATIONS & NOTICES</p>
        <ol type="a">
          <p>
            Ademco may communicate with you by means of electronic communications, including (i) sending electronic mail
            to the email address you provided during registration or (ii) posting notices or communications through the
            Services. You agree that Ademco may communicate with you by means of electronic communications concerning
            this Agreement (including revisions or amendments), notices or disclosures regarding the Services, invoicing
            and payment and any other matter relating to this Agreement. You should maintain copies of electronic
            communications by printing a paper copy or saving an electronic copy. Electronic communications shall be
            deemed received by you when Ademco sends the electronic communication to the email address you provided at
            the time of registration or as appropriately revised by you thereafter, or when Ademco posts the electronic
            communication through the Services. For those communications or records that Ademco is otherwise required
            under applicable law to provide in a written paper form to you, you agree that we may provide such
            communications or records by means of electronic communications. The following additional terms will apply
            to such electronic communications: (1) you may contact us to request another electronic copy of the
            electronic communication without a fee; (2) you may request a paper copy of such an electronic
            communication, and Ademco reserves the right to charge a fee to provide such paper copy; (3) you may contact
            Ademco to update your registration information used for electronic communications or to withdraw consent to
            receive electronic communications. Notwithstanding the foregoing, statements, notices and other
            communications to you may be made by mail, email, postings or other reasonable means. Notice to Ademco may
            be made by mail to:
            <a href="mailto:captureremotesupport@adiglobal.com">captureremotesupport@adiglobal.com</a>
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">28 ELECTRONIC CONTRACTING</p>
        <ol type="a">
          <p>
            Through your downloading, installation of, use of, or payment for the Services, Hardware or other act of
            assent, you acknowledge that you have read and accept this Agreement and that this Agreement is a legally
            binding contract between you and Ademco. You agree that this means of contracting is acceptable and is as
            enforceable as a written paper agreement signed by the parties hereto. If you are entering into the
            Agreement on behalf of a company or other legal entity, you represent that you have the authority to bind
            such entity and its affiliates to this Agreement. If you do not have such authority or do not agree with the
            terms of this Agreement, then you must not accept this Agreement and may not use the Services, Software or
            Documentation.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">29 USE OF SUBSCRIBER'S NAME</p>
        <ol type="a">
          <p>
            Subscriber hereby agrees that Ademco may list Subscriber as an existing or prior Subscriber of Ademco's in
            literature and publications distributed by or on behalf of Ademco with written permission from Subscriber.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">30 EXPORT COMPLIANCE</p>
        <ol type="a">
          <p>
            Subscriber acknowledges that the Software, Hardware and all related technical information, documents and
            materials are subject to export controls under the U.S. Export Administration Regulations. Subscriber
            covenants and agrees to comply with all import and export control regulations of the United States with
            respect to the Software and Hardware. Without limiting the foregoing, Subscriber acknowledges that it may
            not re-export or divert the Software, Hardware or any related technical information, document or material,
            or direct derivatives thereof, to any country set forth on the U.S. Department of Commerce's list of T-7
            countries, including any future changes to the government's list of T-7 countries.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">31 THIRD PARTY SOFTWARE</p>
        <ol type="a">
          <p>
            To the extent the Software or Hardware contains any third-party software code, such third-party software may
            be subject to additional third-party terms and conditions which shall control and govern Subscriber's use of
            the third-party software. Ademco makes no representation or warranty regarding third-party software and
            assumes no liability for any claims arising from third party software, all of which claims Subscriber hereby
            waives and agrees not to pursue against Ademco. <br />
            By clicking ACCEPT I acknowledge that I agree with the terms and conditions contained in this Agreement and
            I confirm that all individuals whose network(s) and device(s) are being monitored by me using the Services
            have been appropriately informed and have agreed to such monitoring
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold text-center">CAPTURE PRIVACY POLICY</p>
        <ol type="a">
          <p>
            This Capture Privacy Policy ("<b>Policy</b>") describes the information that Ademco Inc., doing business as
            ADI Global Distribution, a company incorporated in Delaware with its principal office located at 275
            Broadhollow Road Suite 400, Melville, New York 11747 USA and its relevant subsidiaries, affiliates and
            relevant business partners and service providers (collectively, <b>"Ademco"</b>, <b>"we"</b>, <b>"our"</b>,
            or <b>"us"</b>) collect, use, share, and store through the cloud-based integrated technology platform
            (including relevant software, hardware, subscriptions, website(s) and related services) currently branded as
            "Capture Cloud Service" (the <b>"Services"</b> ).
          </p>
          <p>
            This Policy was last updated on February 18, 2022. We may change it from time to time, so please check back
            regularly to keep informed of any updates.
          </p>
          <p>
            By using the Services, you are accepting and consenting to the practices described in this policy, now and
            as amended by us.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Overview</p>
        <ol type="a">
          <p>
            This Policy is addressed both to Subscribers (and their staff) of our Services as well as other data
            subjects whose personal information Subscribers may collect via the Services.
          </p>
          <p>
            This Policy only applies to the Services and does not apply to any other website, product or service that
            Ademco sells or otherwise makes available (please refer to the relevant privacy policy linked to or shown on
            such other websites, products and services for details). For residents of California, our
            <a target="_blank" href="https://www.resideo.com/us/en/corporate/legal/privacy/ccpa/%22%20%5Ct%20%22_blank"
              >California Consumer Privacy Act — Supplementary Privacy Statement</a
            >
            only applies to the extent personal information of visitors to publicly available websites within the
            Services is concerned
          </p>
          <p>
            In this Policy, personal information means information relating to an identified or identifiable natural
            person. An identifiable person is one who can be identified, directly or indirectly, in particular by
            reference to an identifier such as a name, an identification number, location data, and online identifier or
            to one or more factors specific to his/her physical, physiological, genetic, mental, economic, cultural or
            social identity.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Subscriber's responsibility</p>
        <ol type="a">
          <p>
            Subscribers to our Services are solely responsible for using the Services in a lawful manner and for
            establishing policies for and ensuring compliance with all applicable laws and regulations, as well as any
            and all privacy policies, agreements or other obligations, relating to the collection of personal
            information in connection with the use of our Services by individuals (also referred to as "<b
              >data subjects</b
            >") with whom our Subscribers interact.
          </p>
          <p>
            This Policy, therefore, only describes how the Services collect and process personal information as part of
            their standard operation and you should always refer to the relevant Subscriber's privacy policy for how the
            relevant Subscriber collects and uses personal information (whether such use is within the Services or
            outside of them).
          </p>
          <p>
            In particular, each Subscriber to our Services acknowledges and agrees that:
          </p>

          <ul>
            <li>
              the collection and processing of such information requires each relevant data subject's informed prior
              consent;
            </li>
            <li>
              the Services monitor the Subscriber's customers' relevant network(s) and communications traversing such
              networks, analyze the behavior and presence of devices on such network(s) and store certain information
              relating to such devices and network(s) in order for Subscriber to provide their own services to such
              customer(s);
            </li>
            <li>
              it is solely responsible for informing each relevant data subject and obtaining such consent;
            </li>
            <li>
              it must not use the Services without the informed prior consent of all relevant data subjects and, in the
              event any data subject withdraws their consent, Subscriber must promptly cease using the Services to
              monitor such network(s) and device(s).
            </li>
          </ul>
          <p>
            In monitoring relevant network(s) and device(s) in such networks, the Services collect information under the
            specific direction of our Subscribers. We have no direct relationship with individuals whose personal
            information is processed by Subscriber via the Services.
          </p>
          <p>
            If you are an individual who interacts with a Subscriber using our Services (such as a customer of one of
            our Subscribers) and would either like to amend your contact information, no longer wish to be contacted by
            one of our Subscribers that use our Services or require assistance with any requests or questions relating
            to your personal information, please contact the Subscriber that you interact with directly.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">What information is collected via the Services?</p>
        <ol type="a">
          <p>
            We provide the Services in part using services and technology provided to us by our business partners and
            service providers. In providing the Services, we (and such business partners and service providers) collect
            the following categories of information. Whether we collect particular categories depends on whether you are
            a Subscriber (or Subscriber staff) or another data subject.
          </p>
          <ul>
            <li>
              Information you give us.
            </li>
          </ul>
          <p>
            <u> Account and Registration Information:</u> <br />
            We ask for and may collect personal information about you such as your name, address, phone number, email
            address, instant messaging ID, and credit card information, as well as certain related information like your
            company name and website name, when you register for an account to access or utilize one or more of our
            Services (an "<b>Account</b>"). We also ask for and collect personal information such as an email address and a
            name or alias from any individual that you authorize to log into and utilize our Services in connection with
            your Account. <br />
            We refer to any information described above as "<b>Account Information</b>" for the purposes of this Policy. By
            voluntarily providing us with Account Information, you represent that you are the owner of such personal
            information or otherwise have the requisite consent to provide it to us.
          </p>
          <p>
            <u> Other Submissions: </u> <br />
            We ask for and may collect personal information from you when you submit web forms via the Services or as
            you use interactive features of the Services, including, participation in surveys, requesting customer
            support, or otherwise communicating with us.
          </p>
          <ul>
            <li>
              Information That We Collect via the Services:
            </li>
          </ul>
          <p>
            We may collect information such as zip code, area code, referrer URL, approximate location, and the time
            zone to provide the Services and to assist you in case of troubleshooting.
          </p>
          <ul>
            <li>
              Technical information from your network and devices:
            </li>
          </ul>
          <p>
            We collect technical and diagnostic information about the devices in your monitored networks (including, for
            the avoidance of doubt, the networks of your customer(s) that you monitor via the Services and the device(s)
            that interact with such network(s). For instance, we automatically collect the MAC address, maker name and
            model of such devices, up and down status, operating system version, unique device identifiers and the
            related software. We also collect real time operating status of such network(s) and such connected devices
            (i.e. network speed, IP addresses, device event information such as disconnections, system activity,
            hardware settings, the date and time of such requests) and the related diagnostics information. We process
            information from such devices so that the Services can send you alerts when something happens. We may also
            use this data as described below in the context of the Services, for example, we may use the data in
            aggregated format to show you how such network(s)' performance compares with other networks in your area
          </p>
          <ul>
            <li>
              Information we may receive from other sources
            </li>
          </ul>
          <p>
            We may receive information about you if you use the Services. We work with third parties (including, for
            example, business partners, sub-contractors in technical and delivery services, analytics providers, search
            information providers) and may receive information about you from them
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">How is the information collected via the Services used?</p>
        <ol type="a">
          <p>
            As part of the standard operation of the Services, the information described above is used as follows.
            However, as the Subscriber may also process personal information collected via the Services for other
            purposes, you are encouraged to also refer to the Subscriber's relevant privacy policy
          </p>
          <p>
            We use the information we collect to operate, provide, maintain, protect, develop, promote and troubleshoot
            the Services, such as for:
          </p>
          <ul>
            <li>
              Providing you with the Services, or information you request, for example when you contact us for remote
              support and troubleshooting;
            </li>
            <li>
              Making assessments and recommendations about the Services, other products, services and safety;
            </li>
            <li>
              Providing you with information the Services or the required notices;
            </li>
            <li>
              Customizing your experience when using the Services;
            </li>
            <li>
              Improving and troubleshooting the Services, such as by better tailoring our content to your needs and
              preferences;
            </li>
            <li>
              Internal operations, including maintenance, data analysis, testing, research, statistical and survey
              purposes;
            </li>
            <li>
              Delivering communications about the Services. We may use service providers to send these messages on our
              behalf, but we do not share your email address with any other third parties without your permission; and
            </li>
          </ul>
          <p>
            We may also use such information for the purposes of detecting, preventing, and responding to fraud,
            intellectual property infringement, violations of law, or other misuse of the products and services and as
            otherwise required or permitted under applicable law.
          </p>
          <p>
            We may also combine or aggregate in anonymized or non-personally identifiable format any of the information
            collected through the use of the Services or elsewhere for any of these purposes and for research,
            statistical and business purposes.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">In what circumstances is the information collected via the Services shared?</p>
        <ol type="a">
          <p>
            As part of the standard operation of the Services, the information described above is shared in the
            following circumstances. However, as the Subscriber may also separately share personal information collected
            via the Services, you are encouraged to also refer to the Subscriber's relevant privacy policy.
          </p>
          <p>
            The following are the situations where we may share personal information collected via the Services:
          </p>

          <ul>
            <li>
              Subscribers have access to such information as we act on the subscribers' behalf as their service
              provider.
            </li>
            <li>
              Any member of our corporate group as well as with our relevant business partners and service providers may
              have access to such information in order to operate, provide, maintain, develop, promote and troubleshoot
              the Services. Examples include fulfilling orders, delivering packages, sending postal mail and e-mail,
              providing assistance, processing credit card payments, and providing customer service.
            </li>
            <li>
              Analytics providers and third party developers may have access to aggregated/anonymous information for
              research, statistical, and business purposes and to improve software, hardware and services designed for
              use with the Services
            </li>
            <li>
              If we are involved in a merger, acquisition or sale of all or a portion of our assets, your information
              may be transferred as part of that deal.
            </li>
            <li>
              We may disclose your information to third parties if we determine that such disclosure is reasonably
              necessary to (a) comply with the law (which, depending on where the information is stored, may be
              different from the law of the jurisdiction in which you reside); (b) protect any person from death or
              serious bodily injury; (c) prevent fraud or abuse of Ademco, Ademco's business partners, Ademco's service
              providers or our other users; (d) to enforce or apply our Terms and Conditions applicable to the Services
              and other agreements; or (e) to protect the rights, property, or safety of Ademco or others
            </li>
          </ul>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">How do the Services interact with third parties?</p>
        <ol type="a">
          <p>
            The Services may allow you to interact with third party services. Any information that you choose to share
            with a third party is governed by that third party's privacy policy while in that party's possession. If you
            chose to share such information with a third party, we cannot control the collection, storage or sharing of
            information collected by that party. Always check the privacy policies for any company that collects your
            data and make sure you are requested to grant/deny permission to access your information
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">How do the Services interact with third parties?</p>
        <ol type="a">
          <p>
            The Services may only be used in the USA and Canada.
          </p>
          <p>
            The servers used to provide the Services are located in the United States of America. To provide you with
            the Services when you are on the go, we may need to store, process and transmit information in locations
            around the world from where you are accessing our services- including those outside your country. Those
            countries may not provide the same level of protection for your data as your home country and may be
            available to the governments or agencies under a lawful order of those countries. Where this is the case, we
            will use reasonable efforts to try to ensure that your data is protected in accordance with this privacy
            policy.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">How long is the information collected via the Services stored?</p>
        <ol type="a">
          <p>
            For personal information relating to Subscribers (and their staff), we store such information for as long as
            we provide the Services and, thereafter, if required for legal compliance purposes (e.g., financial
            accounting records) and as part of regular backup procedures. Subscribers can view and edit their
            information via the Services and they can delete their personal information by terminating their account. We
            may retain de-identified data indefinitely.
          </p>
          <p>
            For personal information that we process on behalf of our Subscribers via the Services, we will retain such
            personal information in accordance with the terms of our agreement with such Subscribers. If you wish to
            view, or change personal information relating to you that a Subscriber collected via the Services or if you
            wish the Subscriber to delete such information, please contact the relevant subscriber.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">How is the information collected via the Services secured?</p>
        <ol type="a">
          <p>
            We take security seriously and use administrative, physical, and technical safeguards to protect the
            confidentiality of personal information, including encryption, firewalls and SSL (Secure Sockets Layer).
            However, no information system can be 100% secure, so we cannot guarantee the absolute security of your
            information.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">What are my rights?</p>
        <ol type="a">
          <p>
            You may have certain rights under local law concerning personal information relating to you:
          </p>
          <ul>
            <li>
              If you are a Subscriber (or their staff), you should use the self-service facilities that are part of the
              Services to view, edit or delete personal information relating to you.
            </li>
            <li>
              If a Subscriber collected personal information relating to you via the Services, please contact that
              Subscriber directly to understand and exercise your rights relating to such personal information (if any).
            </li>
          </ul>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Children's Information</p>
        <ol type="a">
          <p>
            The Services are not directed to, nor do we knowingly collect information from, children under the age of
            16. If you become aware that any Subscriber has collected personal information via the Services relating to
            your child or any child under your care, please contact the relevant Subscriber and ask the Subscriber to
            delete such information.
          </p>
        </ol>
      </v-flex>
      <v-flex xs8 offset-xs2 mb-4>
        <p class="font-weight-bold">Contact</p>
        <ol type="a">
          <p>
            If you have any questions and are a Subscriber (or their staff), please contact us via the Services.
          </p>
          <p>
            If you are an individual about whom Subscriber collected personal information via the Services, please
            contact that Subscriber directly. You can usually find their contact details on their website.
          </p>
        </ol>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "TermsADI",
};
</script>
