/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import restapi from "@eencloud/core-components/src/service/CMApi";
import { store } from "@/store";
import { t } from "@eencloud/core-components/src/service/locale";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

const state = {
  account: null,
  accountStatus: null,
  accountDetails: null,
  firstname: null,
  lastname: null,
  country: null,
  language: null,
  telephone: null,
  company: null,
  email: null,
  userSelfServiceManagement: null,
  preventEditUserDetails: false,
  rights: null,
  timeZone: null,
};

const getters = {
  account: (state) => {
    return state.account;
  },
  accountStatus: (state) => {
    return state.accountStatus;
  },
  accountDetails: (state) => {
    return state.accountDetails;
  },
  username: (state) => {
    return state.firstname + " " + state.lastname;
  },
  firstname: (state) => {
    return state.firstname;
  },
  lastname: (state) => {
    return state.lastname;
  },
  country: (state) => {
    return state.country;
  },
  language: (state) => {
    return state.language;
  },
  telephone: (state) => {
    return state.telephone;
  },
  company: (state) => {
    return state.company;
  },
  email: (state) => {
    return state.email;
  },
  userSelfServiceManagement: (state) => {
    return state.userSelfServiceManagement;
  },
  preventEditUserDetails: (state) => {
    return state.preventEditUserDetails;
  },

  rights: (state) => {
    return state.rights;
  },
  isSuperUser: (state) => {
    return state.account ? state.account.accountId === state.account.userId : false;
  },
  timeZone: (state) => {
    return state.timeZone;
  },
};

const mutations = {
  SET_ACCOUNT: (state, account) => {
    state.account = account;
    state.firstname = account ? account.firstName : null;
    state.lastname = account ? account.lastName : null;
    state.email = account ? account.email : null;
  },
  SET_ACCOUNTSTATUS: (state, accountStatus) => {
    state.accountStatus = accountStatus;
  },
  SET_ACCOUNTDETAILS: (state, accountDetails) => {
    state.accountDetails = accountDetails;
    state.country = accountDetails ? accountDetails.country : null;
    state.language = accountDetails ? accountDetails.language : null;
    state.telephone = accountDetails ? accountDetails.phone : null;
    state.company = accountDetails ? accountDetails.companyName : null;
  },
  SET_FIRSTNAME: (state, newValue) => {
    state.firstname = newValue;
  },
  SET_LASTNAME: (state, newValue) => {
    state.lastname = newValue;
  },
  SET_COUNTRY: (state, newValue) => {
    state.country = newValue;
  },
  SET_LANGUAGE: (state, newValue) => {
    state.language = newValue;
  },
  SET_TELEPHONE: (state, newValue) => {
    state.telephone = newValue;
  },
  SET_COMPANY: (state, newValue) => {
    state.company = newValue;
  },
  SET_EMAIL: (state, newValue) => {
    state.email = newValue;
  },
  SET_SELFSERVICE: (state, newValue) => {
    state.userSelfServiceManagement = newValue;
  },
  SET_PREVENTEDIT: (state, newValue) => {
    state.preventEditUserDetails = newValue;
  },
  SET_RIGHTS: (state, newValue) => {
    state.rights = newValue;
  },
  SET_TIMEZONE: (state, newValue) => {
    state.timeZone = newValue;
  },
};

const actions = {
  loadAccountBase({ commit }) {
    return restapi
      .getAccountBase()
      .then((data) => commit("SET_ACCOUNT", data))
      .catch((e) => console.error(e));
  },
  loadAccountStatus({ commit }) {
    return restapi
      .getAccountStatus()
      .then((data) => commit("SET_ACCOUNTSTATUS", data))
      .catch((e) => console.error(e));
  },
  loadAccountDetails({ commit }) {
    return restapi
      .getAccountDetails()
      .then((data) => commit("SET_ACCOUNTDETAILS", data))
      .catch((e) => console.error(e));
  },
  loadFeatureFlags({ commit }) {
    return restapi
      .getFeatureFlags()
      .then((data) => {
        commit("SET_SELFSERVICE", data.userSelfServiceManagement);
        if (data.preventEditUserDetails) commit("SET_PREVENTEDIT", true);
        else commit("SET_PREVENTEDIT", false);
      })
      .catch((e) => console.error(e));
  },
  loadAccountRights({ commit }) {
    return restapi
      .getUserRights()
      .then((data) => commit("SET_RIGHTS", data))
      .catch((e) => console.error(e));
  },
  setAccountName({ commit }, name) {
    return restapi
      .setAccountBase(name)
      .then(() => {
        commit("SET_FIRSTNAME", name.firstName);
        commit("SET_LASTNAME", name.lastName);
        store.dispatch("toastMessage", {
          showing: true,
          text: t("Your details are successfully changed"),
          timeout: 4000,
          color: "primary",
        });
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "UPDATE_USER_NAME"),
          timeout: -1,
          color: "error",
        });
      });
  },
  setAccountEmail({ commit }, data) {
    return restapi
      .isPasswordValid(data.password)
      .then(() => {
        return restapi.setAccountBase(data).then(() => {
          commit("SET_EMAIL", data.email);
          store.dispatch("toastMessage", {
            showing: true,
            text: t("Your details are successfully changed"),
            timeout: 4000,
            color: "primary",
          });
        });
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "UPDATE_USER_NAME"),
          timeout: -1,
          color: "error",
        });
      });
  },
  async setAccounDetails({ commit }, accountDetails) {
    try {
      await restapi.setAccountDetails(accountDetails);
      commit("SET_TELEPHONE", accountDetails.phone);
      commit("SET_COMPANY", accountDetails.companyName);
      commit("SET_COUNTRY", accountDetails.country);
      commit("SET_LANGUAGE", accountDetails.language);
      store.dispatch("toastMessage", {
        showing: true,
        text: t("Your details are successfully changed"),
        timeout: 4000,
        color: "primary",
      });
    } catch (error) {
      console.log(error);
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "UPDATE_USER_DETAILS"),
        timeout: -1,
        color: "error",
      });
    }
  },
  setHelpdesk({ commit, state }, data) {
    return restapi
      .setAccountStatus(data.allowsHelpdeskExpirationDate, data.allowsHelpdeskShowVideo)
      .then(commit("SET_ACCOUNTSTATUS", Object.assign({}, state.accountStatus, data)))
      .catch((e) => {
        store.dispatch("toastMessage", {
          showing: true,
          text: t(`There was an error updating support access. Please try again.`),
          timeout: -1,
          color: "error",
        });
        throw e;
      });
  },
  async setAccountTimeZone({ commit }, data) {
    try {
      await restapi.setAccountTimeZone(data);
      commit("SET_TIMEZONE", data);
      store.dispatch("toastMessage", {
        showing: true,
        text: t("Your details are successfully changed"),
        timeout: 4000,
        color: "primary",
      });
    } catch (error) {
      console.log(error);
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "SET_ACCOUNT_TIMEZONE"),
        timeout: -1,
        color: "error",
      });
    }
  },
  async getAccountTimeZone({ commit }) {
    try {
      const res = await restapi.getAccountTimeZone();
      commit("SET_TIMEZONE", res.timeZone);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
