<template>
  <div id="cookies-block" class="block">
    <v-row>
      <v-col>
        <div class="tile is-ancestor is-vertical what-page-about">
          <div class="tile is-5">
            <article>
              <h2>What is this page about?</h2>
              <p class="description">
                The Dutch government requires us to ask our website's visitors
                permission for the use of cookies. Therefore, a pop-up needs to
                be dispayed, and the company's cookie policy must be explained.
                This page will provide you the required information on our
                cookie policy, and what we use them for.
              </p>
            </article>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="tile why-cookies">
          <article>
            <h2>Why do we use cookies?</h2>
            <p class="description">
              For a company like ours, cookies are very useful. By using them,
              we can get insight in the visitors' browsing behaviour. If we know
              what most people are looking for on the website, we know what
              improvements are required for making our website more
              user-friendly. There are also cookies we can't avoid to use.
              Without those cookies, our website would not be working properly.
            </p>
          </article>
        </div>
      </v-col>
      <v-col>
        <div class="tile which-cookies">
          <article>
            <h2>Which cookies do we use?</h2>
            <p class="description">We basically use two kind of cookies:</p>
            <p class="title">Functional cookies</p>
            <p class="description">
              Functional cookies are the ones that are essential for the
              functioning of the website. We use those kind of cookies to
              facilitate the login function. We also use them for social sharing
              (Facebook, Twitter), and embedded YouTube videos contain cookies
              for their own video analytics.
            </p>
            <p class="title">Tracking cookies</p>
            <p class="description">
              Tracking cookies are cookies we use for analyzing our visitors'
              browsing behaviour.
            </p>
          </article>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Cookies"
};
</script>

<style lang="scss" scoped>
.tile.is-ancestor {
  margin: 0;
}

.what-page-about .tile {
  margin: 0 auto;
  padding-bottom: 70px;
  text-align: center;
  max-width: 900px;
}

p.title {
  background-color: transparent !important;
  font-weight: bold !important;
  margin-bottom: 0px;
}

p.description {
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
}

.why-cookies,
.which-cookies {
  //   background-color: $color-shadow-light;
  padding: 80px 100px;
}

.why-cookies {
  border-right: 3px solid white;
}

.block {
  margin-top: 40px;
}

@media screen and (max-width: 1007px) {
  .what-page-about .tile {
    padding-bottom: 40px;
  }

  .why-cookies,
  .which-cookies {
    padding: 50px 70px;
  }
}

@media screen and (max-width: 767px) {
  .block {
    margin: 20px 20px;
  }

  .what-page-about .tile {
    padding-bottom: 40px;
  }

  .why-cookies {
    border-right: none;
    border-bottom: 3px solid white;
  }
}
</style>
