<template>
  <v-container fluid>
    <v-layout row wrap class="hidden-xs-and-down">
      <v-flex xs8 offset-xs2 pa-0>
        <p class="headline">
          {{
            $t(
              "You are already logged in to the Dealer Dashboard. To sign out from Dealer Dashboard and sign in as an end user, click"
            )
          }}
          <a @click="logout">{{ $t("here") }}</a
          >.
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import auth from "@/service/auth";

export default {
  setup(props, context) {
    if (!auth.isLoggedIntoOtherApp()) {
      context.root.$router.push("/");
    }
    function logout() {
      return auth.logout();
    }
    return { logout };
  }
};
</script>
