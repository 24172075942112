<template>
  <v-container v-if="loaded" class="full-height" fluid ma-0 pa-0>
    <v-row class="full-height">
      <v-col class="pa-0" cols="auto">
        <een-side-menu 
          :showName="false"
          :menus="filteredMenus"
          :currentEndpoint="selectedMenu"
          @itemSelected="navigate"
        >
        </een-side-menu>
      </v-col>
      <v-col class="py-0 px-9">
        <div class="d-flex justify-center">
          <router-view class="settings-container-inner"></router-view>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  reactive,
  toRefs,
  computed
} from "vue";
import { store } from "@/store";
import restapi from "@eencloud/core-components/src/service/CMApi";
import router from "@/service/router";

export default {
  name: "Home",
  setup() {
    const state = reactive({
      route: router,
      loaded: false,
      selectedMenu: computed(() => state.route.currentRoute.name),
      isSuperUser: computed(() => store.getters.isSuperUser),
      rights: computed(() => store.getters.rights),
      hasSubscriptionSelfService: computed(
        () => store.getters.userSelfServiceManagement
      ),
      filteredMenus: computed(() => state.menus.filter(menu => menu.access)),
      menus: computed (() => [
        {
          name: "Details",
          endpoint: "UserDetails",
          icon: "fa fa-id-card",
          access: true
        },
        {
          name: "Support",
          endpoint: "SupportAccess",
          icon: "fa fa-medkit",
          access: true
        },
        {
          name: "Subscriptions",
          endpoint: "subscriptions",
          icon: "fas fa-calendar-check",
          access: state.hasSubscriptionSelfService && state.isSuperUser
        },
          {
          name: "Status Notifications",
          endpoint: "statusnotification",
          icon: "fa-solid fa-bell",
          access: state.rights && state.rights.editCamera
        },
        {
          name: "Two-factor",
          endpoint: "tfa",
          icon: "fa-solid fa-lock",
          access: true
        }
      ])
    });

    const { navigate } = useSideMenu(state);

    initialLoad(state);

    return { ...toRefs(state), navigate };
  }
};

function useSideMenu(state) {
    
    function navigate(menu) {
      if (state.route.currentRoute.name !== menu.endpoint)
        router.push({ name: menu.endpoint });
    }

    return {navigate}
}

async function initialLoad(state) {
  restapi.cancelAll();
  await store.dispatch("loadAccountBase");
  await store.dispatch("loadAccountStatus");
  await store.dispatch("loadAccountDetails");
  await store.dispatch("loadCountries");
  await store.dispatch("loadLanguages");
  await store.dispatch("loadFeatureFlags");
  await store.dispatch("getAccountTimeZone");
  await store.dispatch("getTimeZones");

  state.loaded = true;
  return;
}
</script>

<style lang="scss" scoped>
.full-height {
  height: 100%;
}

@media (min-width: 1904px) {
  .settings-container-inner {
    max-width: 1185px;
  }
}

.no-decoration {
  color: #ffffff;
  text-decoration: none !important;
}
</style>
