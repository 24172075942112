import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/Home";
import Cookies from "@/components/Cookies";
import Terms from "@/components/Terms";
import TermsADI from "@/components/TermsADI";
import Logout from "@/components/Logout";
import LoggedIntoOtherApp from "@/components/LoggedIntoOtherApp";
import Settings from "@/components/Settings";
import SupportAccess from "@/components/settings/SupportAccess";
import Subscriptions from "@/components/settings/Subscriptions";
import StatusNotification from "@/components/settings/StatusNotification";
import Tfa from "@/components/settings/Tfa"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      {
        path: "details",
        name: "UserDetails",
        component: Settings
      },
      {
        path: "support",
        name: "SupportAccess",
        component: SupportAccess
      },
      {
        path: "subscriptions",
        name: "subscriptions",
        component: Subscriptions
      },
      {
        path: "statusnotification",
        name: "statusnotification",
        component: StatusNotification
      },
      {
        path: "tfa",
        name: "tfa",
        component: Tfa
      }
    ]
  },
  {
    path: "/cookies",
    name: "cookies",
    component: Cookies,
    meta: {
      auth: false
    }
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
    meta: {
      auth: false
    }
  },
  {
    path: "/aditerms",
    name: "termsADI",
    component: TermsADI,
    meta: {
      auth: false
    }
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      auth: false,
      title: "Logout"
    }
  },
  {
    path: "/firstlogout",
    name: "Logged in at other app",
    component: LoggedIntoOtherApp,
    meta: {
      auth: false,
      title: "Logged in at other app"
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

export default router;
