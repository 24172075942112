import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import Theme from "@eencloud/core-components/src/service/themes";
import { getConfiguration } from "@eencloud/core-components/src/service/main";

Vue.use(Vuetify);

const config = getConfiguration();
const themeName = config.hasOwnProperty("theme")
  ? config.theme
  : "Cameramanager";

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  theme: new Theme(themeName).colors()
});
